import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../appInsights";
import { auth } from "../authentication/auth";
import configuration from "../configuration/index";

const supportTicketService = {
  getSupportTicketByIdAsync: async (supportTicketId) => {
      appInsights.trackTrace({ message: `Fetching support ticket with ID ${supportTicketId}...`, severityLevel: SeverityLevel.Information });
      
      try {
        const token = await auth.acquireToken(configuration.aceAPIResource, {});
        const response = await fetch(
          `${configuration.aceAPI}/api/aceescalate/SupportTickets/${supportTicketId}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
    
        if (response.ok) {
          const result = await response.json();
          const logData = {
            caseNumber: result.caseNumber,
            caseOwner: result.caseOwner,
            caseCreatedOn: result.caseCreatedOn,
            subscriptionId:result.subscriptionId,
            state: result.state,
            severity: result.severity === 4 ? "C" : (result.severity === 3 ? "B" : (result.severity === 2 ? "A" : "1")),
            product: result.product,
            productPlanningCategory: result.productPlanningCategory,
            queue: result.queue
          };

          appInsights.trackTrace({ message: `Successfully fetched support ticket with ID ${supportTicketId}`, severityLevel: SeverityLevel.Information });
          appInsights.trackTrace({ message: `Support ticket metadata for ID ${supportTicketId}:\n ${JSON.stringify(logData, null, 2)}`, severityLevel: SeverityLevel.Information });
          return result;
        } else {
          appInsights.trackTrace({ message: `Failed to fetch support ticket with ID ${supportTicketId}. Status: ${response.status}`, severityLevel: SeverityLevel.Error });
          console.error(`Failed to fetch support ticket with ID ${supportTicketId}. Status: ${response.status}`);
          throw new Error(`Failed to fetch support ticket. Status: ${response.status}`);
        }
      } catch (error) {
        appInsights.trackTrace({ message: `Error calling getSupportTicketByIdAsync for ID ${supportTicketId}. Error: ${error}`, severityLevel: SeverityLevel.Error });
        console.error(`Error calling getSupportTicketByIdAsync for ID ${supportTicketId}:`, error);
        return null;
      }finally{
        appInsights.trackTrace({ message: `Request for support ticket with ID ${supportTicketId} completed.`, severityLevel: SeverityLevel.Information });
      }
    },
};

export default supportTicketService;