import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../appInsights";
import { auth } from "../authentication/auth";
import configuration from "../configuration/index";

const incidentService = {
  getIncidentsBySRAsync: async (supportTicketId) => {
    appInsights.trackTrace({ message: `Fetching incidents by support ticket ID ${supportTicketId}...`, severityLevel: SeverityLevel.Information });

    try {
      const token = await auth.acquireToken(configuration.aceAPIResource, {});
      const response = await fetch(
        `${configuration.aceAPI}/api/aceescalate/SupportTickets/${supportTicketId}/incidents`,
        {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();

        const _activeAceOnCallIncidents = result.filter((incident) => incident.owningTenantId === 24064 &&
          (incident.owningTeamId === 57198 || incident.owningTeamId === 110786) && incident.status === "ACTIVE");
        const _activeAceLeadsOnCallIncidents = result.filter((incident) => incident.owningTenantId === 24064 &&
          incident.owningTeamId === 58325 && incident.status === "ACTIVE");

        const logData = {
          caseNumber: supportTicketId,
          hasActiveACEOnCallIcM: _activeAceOnCallIncidents.length > 0,
          hasActiveLeadsOnCallIcM: _activeAceLeadsOnCallIncidents.length > 0
        };

        appInsights.trackTrace({ message: `Successfully fetched incidents by support ticket ID ${supportTicketId}`, severityLevel: SeverityLevel.Information });
        appInsights.trackTrace({ message: `Active incidents for support ticket ID ${supportTicketId}:\n ${JSON.stringify(logData, null, 2)}`, severityLevel: SeverityLevel.Information });
        return result;
      } else {
        appInsights.trackTrace({ message: `Failed to fetch incidents by support ticket ID ${supportTicketId}. Status: ${response.status}`, severityLevel: SeverityLevel.Error });
        console.error(`Failed to fetch incidents by support ticket ID ${supportTicketId}. Status: ${response.status}`);
        return null;
      }
    } catch (error) {
      appInsights.trackTrace({ message: `Error calling getIncidentsBySRAsync for support ticket ID ${supportTicketId}. Error: ${error}`, severityLevel: SeverityLevel.Error });
      console.error(`Error calling getIncidentsBySRAsync for support ticket ID ${supportTicketId}:`, error);
      return null;
    } finally {
      appInsights.trackTrace({ message: `Request for incidents by support ticket ID ${supportTicketId} completed.`, severityLevel: SeverityLevel.Information });
    }
  },
  isACEUser: async () => {
    try {
      const token = await auth.acquireToken(configuration.aceAPIResource, {});
      const response = await fetch(`${configuration.aceAPI}/api/acedeprioritization/Incident/IsACEUser`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === 403) {
        return "403";
      }

      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  getIncidentAsync: async (incidentId) => {
    appInsights.trackTrace({ message: `Fetching incidents Id ${incidentId}...`, severityLevel: SeverityLevel.Information });

    try {
      const token = await auth.acquireToken(configuration.aceAPIResource, {});
      const response = await fetch(
        `${configuration.aceAPI}/api/acedeprioritization/Incident/${incidentId}`,
        {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        return null;
      }
    } catch (error) {
      appInsights.trackTrace({ message: `Error calling getIncidentAsync for incidentid ${incidentId}. Error: ${error}`, severityLevel: SeverityLevel.Error });
      console.error(`Error calling getIncidentAsync for incidentid ${incidentId}`, error);
      return null;
    } finally {
      appInsights.trackTrace({ message: `Request for incidents by id ${incidentId} completed.`, severityLevel: SeverityLevel.Information });
    }
  },
  postIncident: async (payload) => {
    appInsights.trackTrace({ message: "Initiating incident...", severityLevel: SeverityLevel.Information });
    const logData = {
      incidentId: payload.incidentId,
      reason: payload.reason,
      hasApproved: payload.hasApproved,
      dePrioritizeRequestedDateTime: payload.dePrioritizeRequestedDateTime,
      IsOverrideRequest: payload.IsOverrideRequest,
      repriortizationNeeded: payload.repriortizationNeeded,
      rePrioritizedDateTime: payload.rePrioritizedDateTime,
    };

    appInsights.trackTrace({ message: `incident metadata for incidentId ${payload.incidentId}:\n ${JSON.stringify(logData, null, 2)}`, severityLevel: SeverityLevel.Information });

    try {
      const token = await auth.acquireToken(configuration.aceAPIResource, {});
      const response = await fetch(
        `${configuration.aceAPI}/api/acedeprioritization/Incident`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),

        }
      );

      if (response.ok) {
        const result = await response.json();
        appInsights.trackTrace({ message: `incident deprioritized successfully for incident ID ${payload.incidentId}`, severityLevel: SeverityLevel.Information });
        return result;
      } else {
        appInsights.trackTrace({ message: `incident deprioritization failed for incident ID ${payload.incidentId}. Status: ${response.status}`, severityLevel: SeverityLevel.Error });
        console.error(`deprioritization failed. Status: ${response.status}`);
        return null;
      }
    } catch (error) {
      appInsights.trackTrace({ message: `Error during deprioritization for incident ID ${payload.incidentId}. Error: ${error}`, severityLevel: SeverityLevel.Error });
      console.error("Error during deprioritization:", error);
      return null;
    }
  },
  getDeprioritizationHistoryAsync: async (incidentId) => {
    appInsights.trackTrace({ message: `Fetching incidents Id ${incidentId}...`, severityLevel: SeverityLevel.Information });

    try {
      const token = await auth.acquireToken(configuration.aceAPIResource, {});
      const response = await fetch(
        `${configuration.aceAPI}/api/acedeprioritization/Incident/GetHistory/${incidentId}`,
        {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        return null;
      }
    } catch (error) {
      appInsights.trackTrace({ message: `Error calling getDeprioritizationHistoryAsync for incidentid ${incidentId}. Error: ${error}`, severityLevel: SeverityLevel.Error });
      console.error(`Error calling getDeprioritizationHistoryAsync for incidentid ${incidentId}`, error);
      return null;
    } finally {
      appInsights.trackTrace({ message: `Request for getDeprioritizationHistory by id ${incidentId} completed.`, severityLevel: SeverityLevel.Information });
    }
  },
};

export default incidentService;