import {  selectorFamily } from "recoil";
import incidentService from "../services/incidentService";

  const incidentsBySRQuery = selectorFamily({
    key: "incidentsBySRQuery",
    get: (supportTicketId) => async () => {
      if (!supportTicketId || typeof supportTicketId !== "string") {
        return null;
      }
  
      try {
        const result = await incidentService.getIncidentsBySRAsync(supportTicketId);
        return result;
      } catch (error) {
        console.error("Error in incidentsBySRQuery:", error);
        return null;
      }
    },
  });

  const isACEUserQuery = selectorFamily({
    key: "isACEUserQuery",
    get: () => async () => {
      const result = await incidentService.isACEUser();
      return result;
    },
  });


  const incidentQuery = selectorFamily({
    key: "incidentQuery",
    get: (incidentId) => async () => {
      if (!incidentId || typeof incidentId !== "string") {
        return null;
      }
  
      try {
        const result = await incidentService.getIncidentAsync(incidentId);
        return result;
      } catch (error) {
        console.error("Error in incidentQuery:", error);
        return null;
      }
    },
  });
  const deprioritizationHistoryQuery = selectorFamily({
    key: "deprioritizationHistoryQuery",
    get: (incidentId) => async () => {
      if (!incidentId || typeof incidentId !== "string") {
        return null;
      }
  
      try {
        const result = await incidentService.getDeprioritizationHistoryAsync(incidentId);
        return result;
      } catch (error) {
        console.error("Error in deprioritizationHistoryQuery:", error);
        return null;
      }
    },
  });

  export { incidentsBySRQuery, isACEUserQuery, incidentQuery, deprioritizationHistoryQuery};