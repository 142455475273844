import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../appInsights';
import { auth } from '../authentication/auth';
import configuration from '../configuration';

const resource = "User.Read";
const resources = resource
  .split(/\s+/)
  .map((value) => value.trim())
  .filter((value) => !!value);

const profileService = {
    getUserInformation: async () => {
      const token = await auth.acquireToken(resources, {});
      const response = await fetch("https://graph.microsoft.com/v1.0/me", {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      const data = await response.json();
      return data;
    },
  
    getMemberGroups: async () => {
      const token = await auth.acquireToken(resources, {});
      const response = await fetch("https://graph.microsoft.com/v1.0/me/getMemberGroups", {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          securityEnabledOnly: true
        }),
      });
      const data = await response.json();
      return data;
    },
  
    getProfilePhoto: async () => {
      const token = await auth.acquireToken(resources, {});
      const response = await fetch("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      const data = await response.blob();
      return data;
    },
  
    getAllUserData: async () => {
      const token = await auth.acquireToken(resources, {});
  
      //Use Promise.all() to fetch data in parallel
      const [userInformation, memberGroups, profilePhoto] = await Promise.all([
        fetch("https://graph.microsoft.com/v1.0/me", {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then(response => response.json()),
        fetch("https://graph.microsoft.com/v1.0/me/getMemberGroups", {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            securityEnabledOnly: true
          }),
        }).then(response => response.json()),
        fetch("https://graph.microsoft.com/v1.0/me/photos/48x48/$value", {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then(response => response.blob())
      ]);
  
      return { userInformation, memberGroups, profilePhoto };
    },
    getUserHierarchyAsync: async (userEmail) => {
      appInsights.trackTrace({ message: `Fetching user hierarchy by user email ${userEmail}...`, severityLevel: SeverityLevel.Information });
      
      try {
        const token = await auth.acquireToken(configuration.aceAPIResource, {});
        const response = await fetch(
          `${configuration.aceAPI}/api/aceescalate/User/${userEmail}/userhierarchy`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
  
        if (response.ok) {
          const result = await response.json();
          appInsights.trackTrace({ message: `Successfully fetched user hierarchy by user email ${userEmail}`, severityLevel: SeverityLevel.Information });
          return result;
        } else {
          appInsights.trackTrace({ message: `Failed to fetch user hierarchy by user email ${userEmail}. Status: ${response.status}`, severityLevel: SeverityLevel.Error });
          console.error(`Failed to fetch user hierarchy by user email ${userEmail}. Status: ${response.status}`);
          return null;
        }
      } catch (error) {
        appInsights.trackTrace({ message: `Error calling getUserHierarchyAsync for user email ${userEmail}. Error: ${error}`, severityLevel: SeverityLevel.Error });
        console.error(`Error calling getUserHierarchyAsync for user email ${userEmail}:`, error);
        return null;
      } finally {
        appInsights.trackTrace({ message: `Request for user hierarchy by user email ${userEmail} completed.`, severityLevel: SeverityLevel.Information });
      }
    },
  }  

export default profileService;