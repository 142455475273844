import { useContext, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../hooks/profile";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ACEEngageContext } from "../contexts/ACEEngageContext";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BugReportIcon from '@mui/icons-material/BugReport';
import ConstructionIcon from '@mui/icons-material/Construction';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const handleHelp = () => {
  window.open("https://microsoft.sharepoint.com/:b:/t/AzureCXPACE/EfVwz1PrZElBhCkWk9UdjxEBHbjrzZGTeKgbgJZeZKBrLQ?e=AFKFFy", "_blank")
}

const handleFeedback = () => {
  window.open("https://forms.office.com/r/Q83SdMiSuQ", "_blank")
}

const handleReportBug = () => {
  window.open("https://msazure.visualstudio.com/One/_workitems/create/Bug?templateId=94f6c0bf-5da9-436a-a610-4286220473e0&ownerId=ed0bc972-09f4-4f3f-9bf9-486284b81822", "_blank")
}

const handleSubmitNewFeature = () => {
  window.open("https://msazure.visualstudio.com/One/_workitems/create/Product%20Backlog%20Item?templateId=977856bf-0952-4d57-9aed-664f74fa463a&ownerId=ed0bc972-09f4-4f3f-9bf9-486284b81822", "_blank")
}

const TheAppBar = ({ handleDrawerOpen, open }) => {

  const { context, setContext } = useContext(ACEEngageContext);

  const [ userProfile ] = useState(useProfile());
  const navigate = useNavigate();

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </Stack>
          <Search id="searchSRComponentId" style={{ marginLeft: "19.2rem" }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Support Request Number"
              inputProps={{ "aria-label": "search", "id": "txt_SR_search_term" }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value !== context.CaseNumber) {
                  setContext((prevContext) => ({
                    ...prevContext,
                    CaseNumber: e.target.value,
                  }));
                  navigate("/");
                }
              }}
            />
          </Search>
          <Search id="searchICMComponentId" style={{ marginLeft: "19.2rem", display: "none" }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="ACE IcM Number"
              inputProps={{ "aria-label": "search", "id": "txt_ICM_search_term" }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.target.value !== context.CaseNumber) {
                  setContext((prevContext) => ({
                    ...prevContext,
                    CaseNumber: e.target.value,
                  }));
                  navigate(`/deprioritization?icm=${e.target.value}`);
                }
              }}
            />
          </Search>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
              <BootstrapTooltip title="Help">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="help"
                  aria-haspopup="true"
                  color="inherit"
                  style={{ marginRight: "0px" }}
                  onClick={handleHelp}
                >
                  <QuestionMarkIcon />
                </IconButton>
              </BootstrapTooltip>
              <BootstrapTooltip title="Submit Feature Request">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="FeatureRequest"
                  aria-haspopup="true"
                  color="inherit"
                  style={{ marginRight: "0px" }}
                  onClick={handleSubmitNewFeature}
                >
                  <ConstructionIcon />
                </IconButton>
              </BootstrapTooltip>
              <BootstrapTooltip title="Report Bug">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="ReportBug"
                  aria-haspopup="true"
                  color="inherit"
                  style={{ marginRight: "0px" }}
                  onClick={handleReportBug}
                >
                  <BugReportIcon />
                </IconButton>
              </BootstrapTooltip>
              <BootstrapTooltip title="Provide Feedback">
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="provide feedback"
                  aria-haspopup="true"
                  color="inherit"
                  style={{ marginRight: "0px" }}
                  onClick={handleFeedback}
                >
                  <SentimentSatisfiedAltIcon />
                </IconButton>
              </BootstrapTooltip>
              <BootstrapTooltip title={userProfile?.user?.displayName}>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  style={{ marginRight: "0px" }}
                >
                  <Avatar alt={userProfile?.user?.displayName} src={userProfile?.photo} />
                </IconButton>
              </BootstrapTooltip>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TheAppBar;