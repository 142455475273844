import { Grid, Typography } from "@mui/material";
import "../GlobalMessage.css";

const WelcomeScreen = () => {
return(
    <Grid container rowSpacing={2} direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
            <Typography gutterBottom variant="h5" component="div" className="welcome_header">
                Welcome to Azure ACE Engage
            </Typography>
        </Grid>
        <Grid item xs={11.5}>
            <Typography gutterBottom variant="body1" component="div" className="welcome_content">
                <div style={{color:"#0D47A1"}}><b>What is ACE Engage?</b></div>
                <div style={{marginBottom:".5rem"}}>Azure ACE Engage is a dedicated channel for support engineers to collaborate with Azure ACE engineers 
                while working with ACE Managed customers. It is a joint effort between CSS and ACE to streamline 
                engagement with Azure ACEs. This process enables support engineers to leverage the expertise and 
                knowledge of the ACE team to provide timely and high-quality support to customers, ultimately leading 
                to efficient problem-solving, higher customer satisfaction, and improved business outcomes.</div>
                Currently, this process is only enabled for Azure Cloud, but we are working on enabling it for other 
                clouds as well.
            </Typography>
        </Grid>
        <Grid item xs={11.5}>
            <Typography gutterBottom variant="body1" component="div" className="welcome_content">
                <div style={{color:"#0D47A1"}}><b>Why ACE Engage?</b></div>
                <div style={{marginBottom:".5rem"}}>One of the key motivations behind the ACE Engage project is to 
                address the lack of a standardized process for the support engineers to reach out to Azure ACEs when 
                they need their expertise in handling customer situations. Previously, without a defined procedure, 
                support engineers faced challenges in efficiently involving ACEs, leading to potential delays in 
                quality communication, engaging PGs, issue resolution, and timely RCA.</div>
                <div style={{marginBottom:".5rem"}}>Moreover, we often receive escalations from CSAMs and customers directly, which is when we activate 
                “Crisis” mode unless we are already engaged in the incident. With ACE Engage, we hope that the signal 
                will come from support instead, as it will be clearer and quicker, and help provide better customer 
                experience. If there is a crisis, it is better for us to hear about it internally from support than 
                from customers as an escalation.</div>
                So, ACE Engage is a Red Button that support can use to escalate internally to Azure ACEs before a 
                customer feels the need to do so.
            </Typography>
        </Grid>
    </Grid>
);
}

export default WelcomeScreen;