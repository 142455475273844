import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../appInsights";
import { auth } from "../authentication/auth";
import configuration from "../configuration/index";

const customerService = {
  getCustomerBySubscriptionIdAsync: async (subscriptionId) => {
    appInsights.trackTrace({ message: `Fetching customer by subscription ID ${subscriptionId}...`, severityLevel: SeverityLevel.Information });
    
    try {
      const token = await auth.acquireToken(configuration.aceAPIResource, {});
      const response = await fetch(
        `${configuration.aceAPI}/api/aceescalate/Customers/subscription/${subscriptionId}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();

        const logData = {
          subscriptionId: result.subscriptionId,
          customerName: result.customerName,
          friendlyName: result.friendlyName,
          isS500: result.isS500,
          isSubInACEScope: result.isSubInACEScope,
          isCritical: result.isCritical,
          availableACEs: result.availableACEs ? result.availableACEs.length : 0
        };

        appInsights.trackTrace({ message: `Successfully fetched customer by subscription ID ${subscriptionId}`, severityLevel: SeverityLevel.Information });
        appInsights.trackTrace({ message: `Customer metadata for subscription ID ${subscriptionId}:\n ${JSON.stringify(logData, null, 2)}`, severityLevel: SeverityLevel.Information });
        return result;
      } else {
        appInsights.trackTrace({ message: `Failed to fetch customer by subscription ID ${subscriptionId}. Status: ${response.status}`, severityLevel: SeverityLevel.Error });
        console.error(`Failed to fetch customer by subscription ID ${subscriptionId}. Status: ${response.status}`);
        return null;
      }
    } catch (error) {
      appInsights.trackTrace({ message: `Error calling getCustomerBySubscriptionIdAsync for subscription ID ${subscriptionId}. Error: ${error}`, severityLevel: SeverityLevel.Error });
      console.error(`Error calling getCustomerBySubscriptionIdAsync for subscription ID ${subscriptionId}:`, error);
      return null;
    } finally {
      appInsights.trackTrace({ message: `Request for customer by subscription ID ${subscriptionId} completed.`, severityLevel: SeverityLevel.Information });
    }
  },
  getAvailableVTeamMembersAsync: async (customerName) => {
    appInsights.trackTrace({ message: `Fetching available V-Team members for ${customerName}...`, severityLevel: SeverityLevel.Information });

    try {
      const token = await auth.acquireToken(configuration.aceAPIResource, {});
      const response = await fetch(
        `${configuration.aceAPI}/api/aceescalate/CustomerVTeam/${customerName}/currentavailable`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        appInsights.trackTrace({ message: `Successfully fetched available V-Team members for ${customerName}`, severityLevel: SeverityLevel.Information });
        return result;
      } else {
        appInsights.trackTrace({ message: `Failed to fetch available V-Team members for ${customerName}. Status: ${response.status}`, severityLevel: SeverityLevel.Error });
        console.error(`Failed to fetch available V-Team members for ${customerName}. Status: ${response.status}`);
        return null;
      }
    } catch (error) {
      appInsights.trackTrace({ message: `Error calling getAvailableVTeamMembersAsync for ${customerName}. Error: ${error}`, severityLevel: SeverityLevel.Error });
      console.error(`Error calling getAvailableVTeamMembersAsync for ${customerName}:`, error);
      return null;
    } finally {
      appInsights.trackTrace({ message: `Request for available V-Team members for ${customerName} completed.`, severityLevel: SeverityLevel.Information });
    }
  },
};

export default customerService;