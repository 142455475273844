import { useRecoilValue } from "recoil";
import { incidentQuery, incidentsBySRQuery, isACEUserQuery, deprioritizationHistoryQuery } from "../recoil/incident";

function useIncidentsBySR(supportTicketId) {
    const incidents = useRecoilValue(incidentsBySRQuery(supportTicketId));
    return incidents;
}

function useIsACEUser() {
    const isAceUser = useRecoilValue(isACEUserQuery());
    return isAceUser;
}

function useIncident(incidentId) {
    const incident = useRecoilValue(incidentQuery(incidentId));
    return incident;
}

function useDeprioritizationHistory(incidentId) {
    const deprioritizationHistory = useRecoilValue(deprioritizationHistoryQuery(incidentId));
    return deprioritizationHistory;
}



export { useIncidentsBySR, useIsACEUser, useIncident, useDeprioritizationHistory };