import { Alert, AlertTitle, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import {useContext, useState} from "react";
import { ACEEngageContext } from "../../../contexts/ACEEngageContext";
import * as Yup from "yup";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SuccessConfirmation from "./message/SuccessConfirmation";
import FailedMessage from "./message/FailedMessage";
import escalationService from "../../../services/escalationService";
import "./NextSteps.css";

const NextSteps = () => {

    const {context} = useContext(ACEEngageContext);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const initialValues = {
        customerTemperature: context.CustomerTemperature,
        escalateReason: context.EscalationReason,
        issueDescription: context.IssueDescription,
        customerImpact: context.CustomerImpact,
        businessImpact: context.BusinessImpact,
        customerBridge: context.CustomerBridge,
        internalBridge: context.InternalBridge,
        currentStatus: context.CurrentStatus,
        additionalRecipients: context.AdditionalRecipients
    }

    const onSubmit = values => {
        setInProgress(true);

        const payload = {
            ...context,
            "CustomerTemperature":values.customerTemperature,
            "EscalationReason":values.escalateReason,
            "IssueDescription":values.issueDescription,
            "CustomerImpact":values.customerImpact,
            "BusinessImpact":values.businessImpact,
            "CustomerBridge":values.customerBridge,
            "InternalBridge":values.internalBridge,
            "CurrentStatus":values.currentStatus,
            "AdditionalRecipients":values.additionalRecipients
        };

        escalationService.escalate(payload).then((res) => {
            setIsFailed(!res);
            setIsSuccess(res);
            setInProgress(false);
        });
    };

    const validationSchema = Yup.object({
        customerTemperature: Yup.string().required("Required"),
        escalateReason: Yup.string().required("Required"),
        issueDescription: Yup.string().required("Required"),
        customerImpact: Yup.string().required("Required"),
        businessImpact: Yup.string().required("Required"),
        customerBridge: Yup.string().required("Required"),
        internalBridge: Yup.string().required("Required"),
        currentStatus: Yup.string().required("Required"),
        additionalRecipients: Yup
            .array()
            .transform(function (value, originalValue) {
            if (this.isType(value) && value !== null) {
                return value;
            }
            return originalValue ? originalValue.split(/[\s;]+/) : [];
            })
            .of(Yup.string().email(({ value }) => `${value} is not a valid email`).lowercase()
            .matches("@microsoft.com($)",({ value }) => `${value} is not a valid microsoft email`))
      });

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

if(!context.HasEscalationAccess)
{
    return (
        <Grid container spacing={0} alignItems="center" direction="row" className="form_container">
            <Grid item xs={10}>
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">
                        <AlertTitle>Escalate Permission Denied!</AlertTitle>
                        <Typography gutterBottom variant="subtitle2" component="div" className="ACE_Escalate_Label">
                            You do not have sufficient permission to escalate an incident to ACE. If you intent to 
                            invoke an escalation, please raise an access request with "Read/Write" permission level in 
                            MyAccess portal and join {" "}
                            <a href="https://myaccess/identityiq/accessRequest/accessRequest.jsf?autoSubmit=true#/accessRequest/review?role=ACE%20Connect%20Users" 
                            target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>ACE Connect Users</a> {" "}project.
                        </Typography>
                    </Alert>
                </Stack>
            </Grid>
        </Grid>
    );
}

return(
    <form onSubmit={formik.handleSubmit}>
        {isSuccess && <SuccessConfirmation />}
        {isFailed && <FailedMessage setIsFailed={setIsFailed} />}
        <Grid container spacing={0} alignItems="start" direction="row" className="form_container">
        {
            context.HasActiveACEOnCallIcM && context.CaseSeverity !== "B" &&
            <Grid item xs={10.15}>
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="info" variant="outlined">
                        <AlertTitle>Escalation to Azure ACE Leads</AlertTitle>
                        <Typography gutterBottom variant="subtitle2" component="div" className="ACE_Escalate_Label">
                            By Escalating to ACE Leads you are indicating you have exhausted other options such as contacting the engaged ACE and available ACE vTeam member(s).
                        </Typography>
                    </Alert>
                </Stack>
            </Grid>
        }
            <Grid container item xs={5} rowSpacing={1} style={{marginRight:"1rem"}}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Customer Temperature:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="lblTemperature">Customer Temperature</InputLabel>
                        <Select
                            name="customerTemperature"
                            labelId="lblTemperature"
                            label="Customer Temperature"
                            error={formik.touched.customerTemperature && Boolean(formik.errors.customerTemperature)}
                            {...formik.getFieldProps('customerTemperature')}
                        >
                        <MenuItem value="Normal">Normal</MenuItem>
                        <MenuItem value="Hot">Hot</MenuItem>
                        <MenuItem value="Crisis">Crisis (Production Outage)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={5} rowSpacing={1}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        What would you need from ACE?
                    </Typography>
                </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="lblReason">Escalation Reason</InputLabel>
                            <Select
                                name="escalateReason"
                                labelId="lblReason"
                                label="Escalation Reason"
                                error={formik.touched.escalateReason && Boolean(formik.errors.escalateReason)}
                                {...formik.getFieldProps('escalateReason')}
                            >
                            <MenuItem value="Help get better traction from Support">Help get better traction from Support</MenuItem>
                            <MenuItem value="Help drive engineering engagement">Help drive engineering engagement</MenuItem>
                            <MenuItem value="Help with RCA">Help with RCA</MenuItem>
                            <MenuItem value="Help with Product Limitation">Help with Product Limitation</MenuItem>
                            <MenuItem value="Incident Management">Incident Management</MenuItem>
                            <MenuItem value="Support on Customer bridge">Support on Customer bridge</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
            </Grid>
            {
                formik.values.escalateReason === "Help get better traction from Support" &&
                    <Grid item xs={10.15}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="error">
                                <AlertTitle>{formik.values.escalateReason}</AlertTitle>
                                <Typography gutterBottom variant="subtitle2" component="div" className="ACE_Escalate_Label">
                                    Support needs/escalations have to be raised through support channels like {" "}
                                    <a href="https://csscsi.azurewebsites.net/Inquiry" rel="noreferrer" target="_blank" className="ACE_Escalate_Link">Escalate Now</a>
                                </Typography>
                            </Alert>
                        </Stack>
                    </Grid>
            }
            {
                (formik.values.escalateReason === "Help drive engineering engagement" && !context.HasActivePGIcM) &&
                    <Grid item xs={10.15}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="warning">
                                <AlertTitle>{formik.values.escalateReason}</AlertTitle>
                                <Typography gutterBottom variant="subtitle2" component="div" className="ACE_Escalate_Label">
                                    ACE drives engineering engagement only when there is an ACTIVE PG IcM linked to the support request. However, there is none in this case.
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Raising an IcM to engineering is recommended before reaching out to ACE. However, if you still wish to engage ACE, you may proceed.
                                </Typography>
                            </Alert>
                        </Stack>
                    </Grid>
            }
            {
                formik.values.escalateReason === "Help with RCA" &&
                    <Grid item xs={10.15}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="warning">
                                <AlertTitle>{formik.values.escalateReason}</AlertTitle>
                                <Typography gutterBottom variant="subtitle2" component="div" className="ACE_Escalate_Label">
                                    RCAs are usually done during business hours and can take 5 business days on average.
                                </Typography>
                                {
                                    context.ACEDL &&
                                    <Typography variant="body2" gutterBottom>
                                        For further details, contact the customer vTeam at {" "}
                                        <a href={`mailto:${context.ACEDL}?subject=Need ACE Assistance`} rel="noreferrer" target='_blank' className="ACE_Escalate_Link">
                                            {context.ACEDL.replace("@MICROSOFT.COM", "")}
                                        </a>. In case of an emergency, you can escalate the issue.
                                    </Typography>
                                }
                            </Alert>
                        </Stack>
                    </Grid>
            }
            {
                formik.values.escalateReason !== "Help get better traction from Support" &&
                <>
                    <Grid item xs={10.15}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="error">
                                <AlertTitle>Before you proceed:</AlertTitle>
                                <Typography variant="body1" gutterBottom>
                                    <strong>DO NOT SUBMIT ANY PII OR SENSITIVE INFORMATION.</strong>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Submitter must provide a full description of customer business impact and justification for escalation in comment box below.
                                </Typography>
                            </Alert>
                        </Stack>
                    </Grid>
                    <Grid item xs={10.15}>
                        <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                            Issue Description:
                        </Typography>
                    </Grid>
                    <Grid item xs={10.15}>
                        <TextField label="Description" name="issueDescription" variant="outlined" fullWidth multiline
                            error={(formik.touched.issueDescription && formik.errors.issueDescription) ? true : false}
                            helperText={formik.touched.issueDescription && formik.errors.issueDescription}
                            {...formik.getFieldProps('issueDescription')} />
                    </Grid>
                    <Grid container item xs={5} rowSpacing={1} style={{marginRight:"1rem"}}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                Customer Impact:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                <TextField label="Description" name="customerImpact" variant="outlined" fullWidth multiline minRows={3}
                                    error={(formik.touched.customerImpact && formik.errors.customerImpact) ? true : false}
                                    helperText={formik.touched.customerImpact && formik.errors.customerImpact}
                                    {...formik.getFieldProps('customerImpact')}/>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={5} rowSpacing={1}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                Business Impact:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                <TextField label="Description" name="businessImpact" variant="outlined" fullWidth multiline minRows={3}
                                    error={(formik.touched.businessImpact && formik.errors.businessImpact) ? true : false}
                                    helperText={formik.touched.businessImpact && formik.errors.businessImpact}
                                    {...formik.getFieldProps('businessImpact')}/>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={5} rowSpacing={1} style={{marginRight:"1rem"}}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                Customer Bridge:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                <TextField label="Bridge Link" name="customerBridge" variant="outlined" fullWidth multiline minRows={3}
                                    error={(formik.touched.customerBridge && formik.errors.customerBridge) ? true : false}
                                    helperText={formik.touched.customerBridge && formik.errors.customerBridge}
                                    {...formik.getFieldProps('customerBridge')}/>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={5} rowSpacing={1}>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                Internal Bridge:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                <TextField label="Bridge Link" name="internalBridge" variant="outlined" fullWidth multiline minRows={3}
                                    error={(formik.touched.internalBridge && formik.errors.internalBridge) ? true : false}
                                    helperText={formik.touched.internalBridge && formik.errors.internalBridge}
                                    {...formik.getFieldProps('internalBridge')}/>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={10.15}>
                        <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                            What is the status of the issue. What have we done already and what are the outcomes and Next Steps? 
                        </Typography>
                    </Grid>
                    <Grid item xs={10.15}>
                        <TextField label="Current Status" name="currentStatus" variant="outlined" fullWidth multiline
                            error={(formik.touched.currentStatus && formik.errors.currentStatus) ? true : false}
                            helperText={formik.touched.currentStatus && formik.errors.currentStatus}
                            {...formik.getFieldProps('currentStatus')}/>
                    </Grid>
                    <Grid item xs={10.15}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="warning">
                                <AlertTitle style={{textDecoration:"underline"}}>IMPORTANT: The quality of information you provide will directly impact the outcomes and prioritization.</AlertTitle>
                            </Alert>
                        </Stack>
                    </Grid>
                    <Grid item xs={10.15}>
                        <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                            Contact for further collaboration: Who should ACE contact to work on this further?
                        </Typography>
                    </Grid>
                    <Grid item xs={10.15}>
                        <TextField label="Email addresses separated by semicolons" name="additionalRecipients" variant="outlined" fullWidth
                            error={(formik.touched.additionalRecipients && formik.errors.additionalRecipients) ? true : false}
                            helperText={formik.touched.additionalRecipients && formik.errors.additionalRecipients}
                            {...formik.getFieldProps('additionalRecipients')}/>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Button variant="contained" color="error" type="submit" disabled={inProgress || isSuccess || isFailed}>
                            {(inProgress) ? "Escalating, please wait..." : ( isSuccess ? "Escalation Succeded!" : (isFailed ? "Escalation Failed!" : "Escalate"))}
                        </Button>
                    </Grid>
                </>
            }
        </Grid>
    </form>
    );
}

export default NextSteps;