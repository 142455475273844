import { memo, useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import HomeIcon from "@mui/icons-material/Home";
import { useIsACEUser } from "../hooks/incident";
import DeprioritizationIcon from '@mui/icons-material/LowPriority';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

const TheSidebar = () => {
  const [hasAceAccess] = useState(useIsACEUser());
  const navigate = useNavigate();
  const classes = useStyles();

  const handleDeprioritizationClick = () => {
    document.getElementById('searchSRComponentId').style.display = "none";
    document.getElementById('searchICMComponentId').style.display = "block";
    navigate('/deprioritization?icm=');
  };

  const handleHomeClick = () => {
    document.getElementById('searchSRComponentId').style.display = "block";
    document.getElementById('searchICMComponentId').style.display = "none";
    navigate('/');
  };

  return (
    <Paper style={{ height: "100vh" }}>
      <List component="nav">
        <NavLink to="/" className={classes.link} onClick={handleHomeClick}>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </NavLink>
        {hasAceAccess && (
          <NavLink to="/deprioritization?icm=" className={classes.link} onClick={handleDeprioritizationClick}>
           <ListItemButton>
              <ListItemIcon>
                <DeprioritizationIcon />
              </ListItemIcon>
              <ListItemText primary="Deprioritization" />
            </ListItemButton>
          </NavLink>
        )}
      </List>
    </Paper>
  );
};

export const MemoizedTheSidebar = memo(TheSidebar);