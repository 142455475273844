import { Grid, Typography } from "@mui/material";
import "../GlobalMessage.css";

const Error = () => {
return(
    <Grid container rowSpacing={1}>
        <Grid item xs={12}>
            <Typography gutterBottom variant="h5" align="center" component="div">
                Unable to fetch the case details, please retry... 
            </Typography>
        </Grid>
    </Grid>
    );
}

export default Error;