import { useContext, useState, useEffect } from 'react';
import { useUserHierarchy } from "./hooks/profile";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "./appInsights";
import { ACEEngageContext } from "./contexts/ACEEngageContext";
import msal from './authentication/auth';
import AppRoutes from "./AppRoutes";

function App() {
  
  // Destructure the context and setContext from useContext
  const { setContext } = useContext(ACEEngageContext);
  const [ hierarchy ] = useState(useUserHierarchy(msal.getAccount().userName));
  
  useEffect(() => {
    if (hierarchy?.length > 0) {
      const { displayName, jobTitle, mail, userPrincipalName } = hierarchy[0];

      const userM1 = hierarchy.length >= 2 ? hierarchy[1].displayName : null;
      const userM2 = hierarchy.length >= 3 ? hierarchy[2].displayName : null;
      const userM3 = hierarchy.length >= 4 ? hierarchy[3].displayName : null;
      
      const userHierarchy = {
        userM1: userM1,
        userM2: userM2,
        userM3: userM3
      };

      const message = `Azure ACE Engage - Begin Session: A new session has been initiated by the user ${displayName} (${userPrincipalName || mail}). \n ${JSON.stringify(userHierarchy, null, 2)}`;
      const severityLevel = SeverityLevel.Information;

      appInsights.trackTrace({ message, severityLevel });
  
      // Use the current context to update it
      setContext((prevContext) => ({
        ...prevContext,
        UserName: displayName,
        UserEmail: userPrincipalName || mail,
        UserJobTitle: jobTitle,
        UserM1: userM1,
        UserM2: userM2,
        UserM3: userM3,
        HasEscalationAccess: true
      }));
    }
  }, [hierarchy]);

  return <AppRoutes />;
}

export default App;