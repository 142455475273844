import {useContext, useState} from "react";
import { ACEEngageContext} from "../../../../contexts/ACEEngageContext";
import Typography from "@mui/material/Typography";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const EscalationToLeads = () => {

    const {context, setContext} = useContext(ACEEngageContext);
    const [selection, setSelection] = useState(context.RequiresFurtherEscalation ? "YES" : "NO");
        
   const handleEscalationToLeadsChange = (event) => {
    setContext((prevContext) => ({
        ...prevContext,
        "RequiresFurtherEscalation":event.target.value === "YES" ? true : false
      }));    
    setSelection(event.target.value);
   };

return(
        <>
            <Typography variant="body1" component="div" gutterBottom style={{display:"flex", alignItems:"center"}}>
                <span style={{marginRight:"2.2rem"}}>Do you still want to escalate it further?</span>
                <RadioGroup row name="escalation-to-leads-group" value={selection} 
                        onChange={handleEscalationToLeadsChange} >
                        <FormControlLabel value="YES" control={<Radio />} label="YES" />
                        <FormControlLabel value="NO" control={<Radio />} label="NO" />
                </RadioGroup>
            </Typography>
            {
                context.RequiresFurtherEscalation &&
                <Typography variant="body2" gutterBottom style={{color:"darkred"}}>
                    <strong>Note</strong>: Further escalation will involve ACE Leads engagement through a Sev 2 IcM to the On-Call ACE Leads, only recommended in critical situations.
                </Typography>
            }
        </>
    );
}

export default EscalationToLeads;