import { ACEEngageContext } from '../../../contexts/ACEEngageContext';
import {useContext} from "react";
import { useIncidentsBySR } from "../../../hooks/incident";
import {useState, useEffect} from "react";
import { Alert, AlertTitle } from "@mui/material";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ACEAlreadyEngaged from './message/ACEAlreadyEngaged';
import PGIncidents from './pg_incidents/PGIncidents';
import LeadsAlreadyEngaged from './message/LeadsAlreadyEngaged';
import ACEOwnerContacted from './acknowledgement/ACEOwnerContacted';
import EscalationToLeads from './acknowledgement/EscalationToLeads';
import NetworkError from './message/NetworkError';
import "./IncidentDetails.css";

const teamMap = {
    "AzureCXPACETenantId":24064,
    "ACEOnCallTeamId":57198,
    "ACELeadsOnCallTeamId":58325,
    "ACEEngageRequestsTeamId": 110786
};

const IncidentDetails = () => {

    const {context, setContext} = useContext(ACEEngageContext);
    const [data] = useState(useIncidentsBySR(context.CaseNumber));
    const [pgIncidents, setPGIncidents] = useState([]);
    const [aceIncidents, setACEIncidents] = useState([]);
    const [hasActiveACEOnCallIcM, setHasActiveACEOnCallIcM] = useState(false);
    const [hasActiveLeadsOnCallIcM, setHasActiveLeadsOnCallIcM] = useState(false);
    
    useEffect(() => {
        if(!data) return;

        const _pgIncidents = data.filter((incident) => incident.owningTenantId !== teamMap.AzureCXPACETenantId && incident.status === "ACTIVE");
        const _aceIncidents = data.filter((incident) => incident.owningTenantId === teamMap.AzureCXPACETenantId && incident.status === "ACTIVE");
        const _aceOnCallIncidents = _aceIncidents.filter((incident) => incident.owningTeamId !== teamMap.ACELeadsOnCallTeamId);
        const _leadsOnCallIncidents = _aceIncidents.filter((incident) => incident.owningTeamId === teamMap.ACELeadsOnCallTeamId);
        
        const _hasActiveACEOnCallICM = _aceOnCallIncidents.length > 0;
        const _hasActiveLeadsOnCallICM = _leadsOnCallIncidents.length > 0;
        const _hasActivePGICM = _pgIncidents.length > 0;

        setPGIncidents(_pgIncidents);
        setACEIncidents(_aceIncidents);
        setHasActiveACEOnCallIcM(_hasActiveACEOnCallICM);
        setHasActiveLeadsOnCallIcM(_hasActiveLeadsOnCallICM);
        setContext((prevContext) => ({
            ...prevContext,
            HasActiveACEOnCallIcM: _hasActiveACEOnCallICM,
            HasActiveLeadsOnCallIcM: _hasActiveLeadsOnCallICM,
            HasActivePGIcM: _hasActivePGICM,
          }));          
    }, [data]);

    !data && <NetworkError />;

    return(
        <Grid container columnSpacing={2} rowSpacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} textAlign={"center"}>
                <Typography variant="h6" gutterBottom>
                    [{((hasActiveACEOnCallIcM && !hasActiveLeadsOnCallIcM) || (!hasActiveACEOnCallIcM && hasActiveLeadsOnCallIcM)) ? "1" : (hasActiveACEOnCallIcM && hasActiveLeadsOnCallIcM ? "2" : "0")}] Engineering Engagement (ACE)
                </Typography>
            </Grid>

            {hasActiveACEOnCallIcM &&
            <Grid item xs={12} md={9} lg={5}>
                <ACEAlreadyEngaged incidents={aceIncidents} />
            </Grid>}

            {hasActiveLeadsOnCallIcM &&
            <Grid item xs={12} md={9} lg={5}>
                <LeadsAlreadyEngaged aceIncidents={aceIncidents} />
            </Grid>}

            {(!hasActiveLeadsOnCallIcM && hasActiveACEOnCallIcM) &&
                <Grid item xs={12} md={12} lg={6}>
                <Alert severity="info" style={{minHeight:"16rem"}} variant="outlined">
                    <AlertTitle>Still want to escalate?</AlertTitle>
                        <ul className="recommendations">
                            <li><ACEOwnerContacted /></li>
                            <li><EscalationToLeads /></li>
                        </ul>
                    </Alert>
                </Grid>}

            {(!hasActiveACEOnCallIcM && !hasActiveLeadsOnCallIcM) &&
            <Grid item xs={12} md={12} lg={10}>
                <Typography variant="subtitle2" gutterBottom className="NO_INCIDENT">
                    Currently, there is no ACE Engagement for this support request Id.
                </Typography>
            </Grid>}
            
            <Grid item xs={12} textAlign={"center"}>
                <hr style={{backgroundColor:"lightgray", border:"none", height:".2px"}}/>
                <Typography variant="h6" gutterBottom>
                    [{pgIncidents.length}] Engineering Engagement (PG)
                </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={11}>
                <PGIncidents incidents={pgIncidents} />
            </Grid>
        </Grid>
    );
}

export default IncidentDetails;