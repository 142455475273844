import { useRecoilValue } from "recoil";
import {
  groupsQuery,
  photoQuery,
  userHierarchyQuery,
  userQuery,
} from "../recoil/profile";
import msal from "../authentication/auth";

function useProfile() {
  const user = useRecoilValue(userQuery);
  const groups = useRecoilValue(groupsQuery);
  const photo = useRecoilValue(photoQuery);

  return {
    user: user,
    groups: groups,
    photo: URL.createObjectURL(photo),
  };
}

function useUserProperties() {
  return {
    name: msal.getAccount().name,
    username: msal.getAccount().userName
  };
}

function useUserHierarchy(userEmail) {
  const hierarchy = useRecoilValue(userHierarchyQuery(userEmail));
  return hierarchy;
}

export { useProfile, useUserProperties, useUserHierarchy};
