import { Alert, AlertTitle, Grid, Typography } from "@mui/material";

const InsufficientPermission = () => {
return(
    <Grid container rowSpacing={3} justifyContent="center">
        <Grid item xs={10}>
            <Alert severity="error" variant="outlined">
                <AlertTitle>Access Verification Issue</AlertTitle>
                <Typography gutterBottom variant="subtitle2" component="div">
                    Oops! It seems there was an issue while verifying your access to the portal. We apologize for the inconvenience. Please refresh the portal or contact 
                    ACE Engage Dev Team at <a href="mailto:aceengagedev@microsoft.com" rel="noreferrer">aceengagedev@microsoft.com</a> for assistance.
                    Thank you for your understanding.
                </Typography>
            </Alert>
        </Grid>
    </Grid>
);
}

export default InsufficientPermission;