const configuration = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  tenantId: process.env.REACT_APP_TENANT_ID,
  authority: process.env.REACT_APP_AUTHORITY,
  applicationInsightsKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
  aceAPI: process.env.REACT_APP_ACE_API,
  aceAPIResource: process.env.REACT_APP_ACE_API_RESOURCE,
  aadToken: process.env.REACT_APP_ADD_TOKEN,
};

export default configuration;