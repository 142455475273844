import { useRecoilValue } from "recoil";
import { availableVTeamMembersQuery, customerBySubscriptionIdQuery } from "../recoil/customer";

function useCustomerBySubscriptionId(subscriptionId) {
    const customer = useRecoilValue(customerBySubscriptionIdQuery(subscriptionId));
    return customer
}

function useAvailableVTeamMembers(customerName) {
    const vTeam = useRecoilValue(availableVTeamMembersQuery(customerName));
    return vTeam
}

export { useCustomerBySubscriptionId, useAvailableVTeamMembers };