import { useState } from 'react';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import "../NextSteps.css";

const FailedMessage = ({setIsFailed}) => {

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setIsFailed(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className='escalate'>
            <div className='NJfJb'>
              <div className='duf3cw-ctrl'>
              <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">
                        <AlertTitle>Escalation Failed!</AlertTitle>
                        <Typography gutterBottom variant="subtitle2" component="div" className="ACE_Escalate_Label">
                            <p>An error occurred during the escalation of your request, trying again may resolve the issue.</p>
                            <Typography variant="body2" gutterBottom>
                              If the error persists, send an email to {" "}
                                    <a href={`mailto:aceengagedev@microsoft.com?subject=[ACE Engage] - An error occurred`} target='_blank' rel='noreferrer' className="ACE_Escalate_Link">
                                      ACE Engage Dev
                                    </a> team with the details.
                            </Typography>
                        </Typography>
                    </Alert>
                </Stack>
                <Stack direction="row" spacing={2} style={{justifyContent:"center", marginTop:"1.5rem"}}>
                  <Button onClick={handleClose}>Close</Button>
                </Stack>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default FailedMessage;