import { IconButton, Paper } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import "./containers.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  height:"1rem",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const TheHeaderLogo = ({ handleDrawerClose }) => {
  const theme = useTheme();
  return (
    <Paper>
      <DrawerHeader>
        <NavLink to="/" className="titleLink">
          <img
            src="images/logo.svg"
            object-fit="contain"
            className="appLogo"
            alt="ACE Engage"
          ></img>
        </NavLink>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
    </Paper>
  );
};

export default TheHeaderLogo;
