import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import "./VTeamContacts.css";

const DisplayCustomerACE = ({TeamsUrl, Name, Photo}) => {
    return(
        <a href={TeamsUrl} target="_blank" rel="noreferrer" className='customer_ace_link'>
            <ListItem alignItems="flex-start" className='customer_ace'>
                <ListItemAvatar className='customer_ace_photo'>
                    <Avatar alt={Name} src={Photo} />
                </ListItemAvatar>
                <ListItemText
                    className='customer_ace_name'
                    primary={Name}
                />
            </ListItem>
        </a>
    );
}
export default DisplayCustomerACE;