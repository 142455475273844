import { Alert, AlertTitle, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import "./CustomerMessage.css";

const ACEManagedCustomer = ({customerName}) => {
return(
    <Grid item xs={12} className="message">
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="success">
                <AlertTitle>Azure ACE Managed Customer</AlertTitle>
                {customerName} is a part of Azure ACE Program
            </Alert>
        </Stack>
    </Grid>
    );
};

export default ACEManagedCustomer;