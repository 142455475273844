import { Alert, AlertTitle, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import "./CustomerMessage.css";

const NonACEManagedCustomer = () => {
return(
    <Grid item xs={12} className="message">
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">
                <AlertTitle>Non ACE Managed Customer</AlertTitle>
                    You cannot escalate this incident to ACE because the customer is not part of the Azure ACE Program
            </Alert>
        </Stack>
    </Grid>
    );
};

export default NonACEManagedCustomer;