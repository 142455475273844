import { Routes, Route } from "react-router-dom";
import TheLayout from "./containers/TheLayout";
import Home from "./views/Home";
import Page404 from "./pages/page404/Page404";
import WelcomeScreenDeprioritization from "./components/messages/welcome/WelcomeScreenDepriortization";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" name="Home" element={<TheLayout />}>
        <Route
          index
          path="/"
          name="Home"
          element={<Home />}
        />
        <Route
          index
          path="/:SR"
          name="Home"
          element={<Home />}
        />
        <Route
          index
          path="/:ICM"
          name="Home"
          element={<Home />}
        />
        <Route
          index
          path="/deprioritization"
          name="Deprioritization"
          element={<WelcomeScreenDeprioritization />}
        />
      </Route>
      <Route path="*" name="NoMatch" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoutes;