import { selectorFamily } from "recoil";
import supportTicketService from "../services/supportTicketService";

  const supportTicketByIdQuery = selectorFamily({
    key: "supportTicketByIdQuery",
    get: (supportTicketId) => async () => {
      if (!supportTicketId || supportTicketId.length === 0) {
        return null;
      }
  
      try {
        const result = await supportTicketService.getSupportTicketByIdAsync(supportTicketId);
        return result;
      } catch (error) {
        console.error("Error in supportTicketByIdQuery:", error);
        return null;
      }
    },
  });

  export { supportTicketByIdQuery};