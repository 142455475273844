import { Typography } from "@mui/material";
import Incident from "./Incident";
import "../IncidentDetails.css";

const PGIncidents = ({incidents}) => {
 return(
    <>
       {
        incidents.length > 0 &&
        incidents.map((incident) => (
            <div className="incident Non_ACE_On_Call_Border_Left" key={incident.incidentId}>
                <Incident incident={incident} />
            </div>
        ))}
        {
            incidents.length === 0 && (
            <Typography variant="subtitle2" gutterBottom className="NO_INCIDENT">
                Currently, there is no PG engagement for this support request Id.
            </Typography>
        )}
    </>
);
}

export default PGIncidents;