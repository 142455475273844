import ReactDOM from 'react-dom/client';
import App from "./App";
import { auth } from "./authentication/auth";
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import {
  AuthProvider,
  WithUser,
  WithoutUser,
} from "@iamexperiences/react-auth";
import { Suspense } from 'react';
import ACEEngageContextProvider from './contexts/ACEEngageContext';

auth.init({
  // This causes query parameters (e.g. upn, tenant) to trigger an auto-login.
  autoLogin: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider auth={auth}>
    <WithUser login={true}>
      <BrowserRouter>
          <RecoilRoot>
            <Suspense fallback={<span>Please wait, verifying the necessary permissions to access the portal...</span>}>
              <ACEEngageContextProvider>
                <App />
              </ACEEngageContextProvider>
            </Suspense>
          </RecoilRoot>
      </BrowserRouter>
    </WithUser>
    <WithoutUser>Authenticating...</WithoutUser>
  </AuthProvider>
);