import { Grid, Typography } from "@mui/material";
import "../GlobalMessage.css";
import { Suspense, useContext, useEffect, useState } from "react";
import { ACEEngageContext } from "../../../contexts/ACEEngageContext";
import Loading from "../../../components/utilities/Loading";
import Deprioritization from "../../../components/home/deprioritization/Deprioritization";
import { useIsACEUser } from "../../../hooks/incident";
import { useLocation } from "react-router-dom";
import InsufficientPermission from "../insufficient_permission/InsufficientPermission";

function findCaseInsensitiveParam(params, paramName) {
    for (const [key, value] of params.entries()) {
        if (key.toLowerCase() === paramName.toLowerCase()) {
            return value;
        }
    }
    return null;
}
const WelcomeScreenDeprioritization = () => {
    const [hasAceAccess] = useState(useIsACEUser());
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const incidentId = findCaseInsensitiveParam(params, 'icm');
    const { context, setContext } = useContext(ACEEngageContext);
    const [request, setRequest] = useState('');
    const { CaseNumber } = context;

    useEffect(() => {
        document.getElementById('searchSRComponentId').style.display = "none";
        document.getElementById('searchICMComponentId').style.display = "block";
    }, [hasAceAccess]);

    useEffect(() => {
        if (incidentId) {
            document.getElementById("txt_ICM_search_term").value = incidentId;
            setContext((prevContext) => ({
                ...prevContext,
                CaseNumber: incidentId,
            }));
            setRequest("icm");
        }
    }, [location]);

    if (request === "icm") {
        return (
            <Suspense fallback={<Loading />}>
                <Deprioritization key={CaseNumber} incidentId={CaseNumber} />
            </Suspense>
        );
    }
    return (
        !hasAceAccess ? (
            <InsufficientPermission />
        ) : (
            <Grid container rowSpacing={2} direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="div" className="welcome_header">
                        Welcome to ACE OnCall De-prioritization Engine
                    </Typography>
                </Grid>
                <Grid item xs={11.5}>
                    <Typography gutterBottom variant="body1" component="div" className="welcome_content">
                        <div><b>What is De-prioritization Engine?</b></div>
                        As a part of continuous optimization of ACE OnCall bandwidth,
                        De-Prioritization engine has been designed and is aimed at automatically
                        removing Incidents from the command center essentially taking them out from
                        ACE OnCall visibility when a certain criterion is met.
                        This is in line with the efforts to enhance efficiency and enable On-call persona
                        to focus on Active/impactful incidents only while disengaging from passive Incidents
                        in a balanced manner. This effort will also help us to evangelize
                        {' '}<a href="https://aka.ms/aceengage" target="_blank" rel="noopener noreferrer">https://aka.ms/aceengage</a> and improve its usage in an expedited fashion with all CSS SBUs.
                    </Typography>
                </Grid>
                <Grid item xs={11.5}>
                    <Typography gutterBottom variant="body1" component="div" className="welcome_content">
                        <b>De-prioritization Rules</b>
                        <div style={{ marginBottom: ".5rem" }}>Below rules are applicable for incidents handled by On-call members and are not applicable if they are being handled by the V-Team members.
                            <ul>
                                <li>Cases triaged as green and remain in the same state for at least 6 hours.</li>
                                <li>Cases triaged as amber with low risk (No collab task + No PG IcM) for at least 6 hours.</li>
                            </ul>
                        </div>
                        The engine, when it identifies incidents that match the above rules,
                        would send notifications to ACE ICM (Incident Management) owner.
                        These notifications would be sent 30 minutes before the threshold as per the definition of the rules.
                        The user's consent will be requested if the case can be De-prioritized. By default, if no action is performed,
                        the engine will, at a later stage, de-prioritize the ICM Incident.
                        If the On-call persona believes the case needs ACE engagement
                        even with the green category, the system will respect the user's decision all the time.
                    </Typography>
                </Grid>
                <Grid item xs={11.5}>
                    <Typography gutterBottom variant="body1" component="div" className="welcome_content">
                        <div><b>ACE De-prioritization Workflow</b></div>
                        Type the ACE IcM number into the search bar and hit enter to proceed.
                    </Typography>
                </Grid>

                <Grid item xs={11.5}>
                    <Typography gutterBottom variant="body1" component="div" className="welcome_content">
                        <div><b>What's new</b>
                            <ul>
                                <li><b>Snooze feature</b>: This allows ACE OnCall to successfully deprioritize but at the same time consider an option of auto upgrading in x hours when there is a need for customer reconvene.
                                    Example scenario to consider this option. Issue mitigated and customer wants to wait for 6 hours before their peak traffic starts to validate the system. ACE OnCall to hit snooze for next 6 hours which essentially removes this from ACE OnCall radar informing CSS, Primary ACE and reprioritizes same ACE ICM after 6 hours for ACE/OnCall visibility.</li>
                                <li><b>Manual trigger for De-prioritization</b>: This allows ACE OnCall to take a judgement call and deprioritize any Incident in CC (Command Center) irrespective of whether automated criteria is matched or not as long as situation demands.
                                    OnCall ACEs can use {' '}<a href="https://aka.ms/aceengage" target="_blank" rel="noopener noreferrer">https://aka.ms/aceengage</a> to disengage on any ACE ICM at any point of time.
                                    Example scenario, ACE OnCall or primary ACE feels there is no need for ACE to continue on this Incident as it is in the right track with CO (Case Owner) leading and there is a potential mitigation in next hour or so, ACE can disengage immediately with this manual trigger and system takes care of all communications to CSS and primary ACE team. </li>
                                <li><b>Override feature</b>: This allows ACEs to come back and override previously set options based on how the incident proceeds in its lifecycle. Let's say for example, the incident was snoozed but the previous data/time configured for reconvene has changed. ACEs could use this feature to reset the previous data to either snooze it to different date/time or even deprioritize it immediately if there is no reconvene required. </li>
                                <li><b>Incident History</b>: With override capability, ACEs could take decisions to deprioritize, reprioritize, snooze any number of times in a lifecycle of the incident and as long as it is Active (with DfM ticket at sev 1/A). This historical perspective provides insights into the various changes that have been made, who implemented these modifications, and the reasons behind these actions.</li>
                            </ul>
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={11.5}>
                    <Typography gutterBottom variant="body1" component="div" className="welcome_content">
                        <div>For additional information, kindly refer to the documentation available at  {' '}<a href="https://aka.ms/acedisengagedoc" target="_blank" rel="noopener noreferrer">https://aka.ms/acedisengagedoc</a>.</div>
                    </Typography>
                </Grid>

            </Grid>
        ));
}

export default WelcomeScreenDeprioritization;
