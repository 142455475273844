import React from "react";
import moment from "moment";
import { Avatar, Stack, Grid, Typography, Divider } from "@mui/material";
import "./AceIncident.css";

const AceIncident = ({ data }) => {

  const categoryColor = {
    RED: "red",
    AMBER: "orange",
    GREEN: "green",
  }[data.sevACategory] || "black";

  return (
    <Grid container spacing={1} className="incident_details">
      <Grid item xs={12} md={6} lg={2} className={`incident_head border-left-${data.sevACategory}`}>
        <Grid container rowSpacing={1} direction="row" justifyContent="center" alignItems="center" textAlign={"center"}>
          <Grid item xs={12}>
            <a href={`https://portal.microsofticm.com/imp/v3/incidents/details/${data.incidentId}/home`} target="_blank" rel="noreferrer" className="ACE_Escalate_Link">
              {data.incidentId}
            </a>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none' }}>
              Triage Category
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom style={{ color: categoryColor }}>
              {data.sevACategory}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={10} alignSelf={"center"}>
        <Grid container rowSpacing={1} direction="row">
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              {data.incidentTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ borderBottom: "1px solid lightgray" }}></div>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="subtitle2" gutterBottom className="ACE_Escalate_Label">
                  Owning Team Id: 
                </Typography>
                <span className="spnIncidentValue">{`${data.owningTeamName}`}</span>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle2" gutterBottom className="ACE_Escalate_Label">
                  Owner:
                </Typography>
                <Stack direction="row" spacing={1} alignItems="self-start">
                  {data.owningContactAlias !== undefined ? (
                    <>
                      <Avatar sx={{ width: 27, height: 27 }} alt={data.owningContactAlias} src={`https://microsoft.sharepoint.com/teams/azure_ace_program/_layouts/15/UserPhoto.aspx?Size=L&AccountName=${data.owningContactAlias}@microsoft.com`} />
                      <a href={`https://teams.microsoft.com/l/chat/0/0?users=${data.owningContactAlias}@microsoft.com`} target="_blank" rel="noreferrer" className="ACE_Escalate_Link">
                        {data.owningContactAlias}
                      </a>
                    </>
                  ) : (
                    <Typography gutterBottom variant="subtitle2" component="span" className="NoAgent">
                      Not Assigned
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="subtitle2" gutterBottom className="ACE_Escalate_Label">
                  Created:
                </Typography>
                <span className="spnIncidentValue">{moment(data.incidentCreatedDateTime).format("lll")}</span>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                  ASC:
                </Typography>
                <span className="spnIncidentValue">
                  <a href={`https://azuresupportcenter.msftcloudes.com/solutionexplorer?srId=${data.supportTicketId}`} target="_blank" rel="noreferrer" className="ACE_Escalate_Link">{data.supportTicketId}</a></span>
              </Stack>

            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AceIncident;
