import { useState } from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import moment from "moment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    header: {
        fontWeight: "100",
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#1e88e5',
    }
}));

const DeprioritizationHistory = ({ history }) => {
    const classes = useStyles();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const getActionValue = (item) => {
        if (item.repriortizationNeeded) {
            return "Snoozed";
        } else if (item.repriortizationNeeded === false && item.isDepriortized) {
            return "Deprioritized";
        } else if (item.repriortizationNeeded === false && item.isDepriortized === false) {
            return "Engaged";
        }
    };
    const getActionPermormedByValue = (item) => {
        if (item.reason === "") {
            return "System";
        } else {
            return item.approvedContactAlias;
        }
    };
    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <div className={classes.container}>
            <div style={{ marginTop: '2rem' }}>
                <span>
                    Click to see the deprioritization{"  "}
                    <a
                        href="#"
                        className={classes.link}
                        onClick={handleOpenDialog}
                    >
                        history
                    </a>
                </span>
            </div>
            <Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="xl"
                PaperProps={{
                    style: {
                        width: '70%',
                    },
                }}
            >
                <DialogTitle id="dialog-title">Deprioritization History</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '20%' }}>Action Performed By</TableCell>
                                    <TableCell style={{ width: '20%' }}>Action Taken</TableCell>
                                    <TableCell style={{ width: '35%' }}>Reason</TableCell>
                                    <TableCell style={{ width: '15%' }}>
                                        <span>Time</span>
                                        <span style={{ fontSize: '0.8em' }}> (Local TimeZone)</span>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>IsOverride</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {history.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{getActionPermormedByValue(item)}</TableCell>
                                        <TableCell>{getActionValue(item)}</TableCell>
                                        <TableCell>{item.reason}</TableCell>
                                        <TableCell>{moment(item.ingestionTime).format("lll")}</TableCell>
                                        <TableCell>{item.isOverrideRequest ? "Yes" : "No"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeprioritizationHistory;
