import { Alert, AlertTitle, Grid, Typography, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import "./Deprioritization.css";
import { useIsACEUser, useIncident, useDeprioritizationHistory } from "../../../hooks/incident";
import AceIncident from "./AceIncident";
import { RadioGroup, FormControlLabel, FormControl, Radio, Snackbar } from "@mui/material";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useProfile } from "../../../hooks/profile";
import incidentService from "../../../services/incidentService";
import Button from "@mui/material/Button";
import { useLocation } from 'react-router-dom';
import TimezoneLegend from "./TimezoneLegend";
import { appInsights } from "../../../appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import Invalid from "../../messages/error/Invalid";
import DeprioritizationHistory from "./DeprioritizationHistory";

const Deprioritization = ({ incidentId }) => {
    const [rePrioritizedDateTime, setRePrioritizedDateTime] = useState("");
    const location = useLocation();
    const [hasAceAccess] = useState(useIsACEUser());
    const [data] = useState(useIncident(incidentId));
    const [deprioritizationHistory] = useState(useDeprioritizationHistory(incidentId));
    const [userProfile, setUserProfile] = useState(useProfile());
    const [inProgress, setInProgress] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const [isradioButtonDisabled, setIsRadioButtonDisabled] = useState(false);
    const [showDateTimeField, setShowDateTimeField] = useState(false);
    const [overrideButtonClicked, setOverrideButtonClicked] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [labelText, setLabelText] = useState("");
    const [uncheckRadioButton, setUncheckRadioButton] = useState(false);
    const [showOverrideButton, setShowOverrideButton] = useState(false);
    const handleClose = () => {
        setOpen(false);
        setIsFailed(false);
    };
    useEffect(() => {
        document.getElementById('searchSRComponentId').style.display = "none";
        document.getElementById('searchICMComponentId').style.display = "block";
        if (!hasAceAccess) {
            appInsights.trackTrace({
                message:
                    "The user is not authorized to perform the deprioritization of the incident",
                severityLevel: SeverityLevel.Warning,
            });
        }
    }, [hasAceAccess]);
    useEffect(() => {
        if (data !== null) {
            if (data.status !== undefined && !(data.status.toLowerCase() === "active")) {
                if (data.reason !== "") {
                    if (data.rePrioritizedDateTime === "0001-01-01T00:00:00Z") {
                        setLabelText(
                            <div>
                                The current state for the incident is {data.status.toLowerCase()}. The deprioritization workflow can only be used for active incidents.
                                <br />
                                This incident was last deprioritized on {moment(data.dePrioritizedDateTime).utc().format("lll")} by {data.approvedContactAlias}.
                            </div>
                        );
                    } else {
                        setLabelText(
                            <div>
                                The current state for the incident is {data.status.toLowerCase()}. The deprioritization workflow can only be used for active incidents.
                                <br />
                                This incident was last deprioritized on {moment(data.dePrioritizedDateTime).utc().format("lll")} by {data.approvedContactAlias} and was reprioritized at {moment(data.rePrioritizedDateTime).utc().format("lll")} UTC.
                            </div>
                        );

                    }
                } else {
                    if (data.isDepriortized) {
                        setLabelText(
                            <div>
                                The current state for the incident is {data.status.toLowerCase()}. The deprioritization workflow can only be used for active incidents.
                                <br />
                                This incident was deprioritized at {moment(data.dePrioritizedDateTime).format("lll")} by system.
                            </div>
                        );
                    }
                    else {
                        setLabelText(`The current state for the incident is ${data.status.toLowerCase()}. The deprioritization workflow can only be used for active incidents.`);
                    }
                }
                setSubmitButtonDisabled(true);
                setIsRadioButtonDisabled(true);
            }
            else if (data.repriortizationNeeded === true) {
                const currentDateTime = moment.utc();
                const rePrioritizedDateTime = moment(data.rePrioritizedDateTime).utc();
                if (currentDateTime.isBefore(rePrioritizedDateTime)) {
                    setLabelText(`This incident was deprioritized on ${moment(data.dePrioritizedDateTime).utc().format("lll")} by ${data.approvedContactAlias} and will be reprioritized again at ${moment(data.rePrioritizedDateTime).utc().format("lll")} UTC.`);
                }
                else {
                    setLabelText(`This incident was deprioritized on ${moment(data.dePrioritizedDateTime).utc().format("lll")} by ${data.approvedContactAlias} and was reprioritized at ${moment(data.rePrioritizedDateTime).utc().format("lll")} UTC.`);
                }
                setSubmitButtonDisabled(true);
                setIsRadioButtonDisabled(true);
                setShowOverrideButton(true);
            } else if (data.isDepriortized === false) {
                if (!data.approvedContactAlias) {
                    if (data.dePrioritizeRequestedDateTime === "0001-01-01T00:00:00Z") {
                        setLabelText("This incident has not been identified as a candidate for deprioritization. Do you still agree that on-call could get disengaged at this stage?");
                        setUncheckRadioButton(true);
                        setIsRadioButtonDisabled(false);
                        setSubmitButtonDisabled(false);
                    } else {
                        setLabelText(`Do you agree that on-call could get disengaged at this stage? If you don't provide any response, the system will auto deprioritize at ${moment(data.dePrioritizeRequestedDateTime).format("lll")}.`);
                        if (data.createdAtUtc !== null) {
                            const DefaultDePrioritization_PERIOD = 3900000; //65 mins
                            const MILLISECONDS_IN_PERIOD = Number(process.env.DefaultDePrioritization_TIME_DIFFERENCE) || DefaultDePrioritization_PERIOD;
                            const dePrioritizeRequestedDateTime = new Date(data.dePrioritizeRequestedDateTime);
                            const createdAtUtc = new Date(data.createdAtUtc);

                            if (dePrioritizeRequestedDateTime - createdAtUtc >= MILLISECONDS_IN_PERIOD) {
                                // The difference is more than 1 hour
                                setSubmitButtonDisabled(true);
                                setIsRadioButtonDisabled(true);
                                setShowOverrideButton(true);
                            }
                            else {
                                setUncheckRadioButton(true);
                                setSubmitButtonDisabled(false);
                                setIsRadioButtonDisabled(false);
                            }
                        }
                    }
                } else {
                    setLabelText(`This incident was last set by ${data.approvedContactAlias} with below details. Do you still agree that on-call could get disengaged at this stage?`);
                    setSubmitButtonDisabled(true);
                    setIsRadioButtonDisabled(true);
                    setShowOverrideButton(true);
                }
            } else if (data.isDepriortized === true) {
                setLabelText(!data.approvedContactAlias
                    ? `This incident was deprioritized at ${moment(data.dePrioritizedDateTime).format("lll")} by system.`
                    : `This incident was deprioritized at ${moment(data.dePrioritizedDateTime).format("lll")} by ${data.approvedContactAlias}`);
                setSubmitButtonDisabled(true);
                setShowOverrideButton(true);
                setIsRadioButtonDisabled(true);
            }
        }
    }, [data, setSubmitButtonDisabled, setIsRadioButtonDisabled]);


    const validationSchema = Yup.object().shape({
        reason: Yup.string().required("Reason is required"),
        isApproved: Yup.string().required("Please select Yes or No"),
    });

    const initialValues = {
        isApproved: (uncheckRadioButton || (data.status !== undefined && !(data.status.toLowerCase() === "active") && data.reason !== "")) ?
            "true" :
            data.status !== undefined && !(data.status.toLowerCase() === "active") ? "" :
                (data && data.repriortizationNeeded) ?
                    "snooze" :
                    (data && data.hasApproved) ?
                        "true" :
                        "false",
        reason: data ? data.reason || "" : "",
    };

    const formik = useFormik({
        initialValues,
        onSubmit: async (values) => {
            setInProgress(true);
            const tempPayload = {
                ...data,
                dePrioritizedDateTime: moment().utc().format("YYYY-MM-DD hh:mm:ss A"),
                approvedContactAlias: userProfile.user.mail,
                hasApproved: values.isApproved === "true",
                reason: values.reason,
                repriortizationNeeded: false,
                rePrioritizedDateTime: null,
            };
            if (values.isApproved === "snooze") {
                tempPayload.hasApproved = true;
                tempPayload.repriortizationNeeded = true;
                tempPayload.rePrioritizedDateTime = moment(rePrioritizedDateTime).format("YYYY-MM-DD HH:mm:ss A");
            }
            if (overrideButtonClicked) {
                tempPayload.IsOverrideRequest = true;
            } else {
                tempPayload.IsOverrideRequest = false;
            }

            incidentService.postIncident(tempPayload).then((result) => {
                if (result !== null) {
                    setOpen(true);
                    document.getElementById("submitButton").disabled = true;
                    const newUrl = `${location.pathname}?icm=${incidentId}`;
                    window.history.pushState({}, "", newUrl);
                    setTimeout(() => {
                        window.location.reload();
                    }, 10000);
                } else {
                    setIsSuccess(false);
                    setIsFailed(true);
                }
            });
        },
        validationSchema,
    });

    const handleOverrideClick = () => {
        const confirmed = window.confirm("Are you sure you want to override?");
        if (confirmed) {
            formik.resetForm();
            formik.setFieldValue('isApproved', '');
            formik.setFieldValue('reason', '');
            setShowDateTimeField(formik.values.isApproved === "snooze");
            setIsRadioButtonDisabled(false);
            setSubmitButtonDisabled(false);
            setOverrideButtonClicked(true);
        }
    };

    return hasAceAccess ? (
        <>
            {data === null ? (
                <Invalid error={"Invalid ACE IcM"} />
            ) : (
                <>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={11}>
                        <div className="bh13Qc nuboAb aneZWd">
                            <div className="cv2VAd innercard" style={{ paddingTop: "20px" }}>
                                <Grid container rowSpacing={0} spacing={0}>
                                    <Grid item xs={12}>
                                        <TimezoneLegend />
                                        <AceIncident data={data} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <Typography variant="subtitle1" gutterBottom>
                                        {labelText}
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-label="isApproved"
                                        name="isApproved"
                                        value={formik.values.isApproved}
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                            const selectedValue = event.target.value;
                                            setShowDateTimeField(selectedValue === "snooze");
                                        }}
                                        required
                                    >
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio disabled={isradioButtonDisabled} />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio disabled={isradioButtonDisabled} />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            value="snooze"
                                            control={<Radio disabled={isradioButtonDisabled} />}
                                            title="This incident will be deprioritized now and will be resent to ACE on-call at the specified future time in UTC."
                                            label="Snooze (Deprioritize now and reprioritize)"
                                        />
                                    </RadioGroup>
                                    <Typography variant="body2" color="error">
                                        {formik.touched.isApproved && formik.errors.isApproved}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            {formik.values.isApproved === 'snooze' && showDateTimeField && (
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Select future date and time (UTC) at which the incident should be reprioritized and sent to ACE on-call
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="datetime-local"
                                        name="rePrioritizedDateTime"
                                        value={rePrioritizedDateTime || moment.utc().format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => {
                                            const selectedDate = e.target.value;
                                            if (selectedDate < moment.utc().format('YYYY-MM-DDTHH:mm')) {
                                                return;
                                            }
                                            setRePrioritizedDateTime(selectedDate);
                                        }}
                                        error={!rePrioritizedDateTime && formik.values.isApproved === 'snooze'}
                                        helperText={!rePrioritizedDateTime && formik.values.isApproved === 'snooze' && 'Date is required in UTC'}
                                        style={{ width: '24%' }}
                                    />
                                </Grid>
                            )}

                            {showOverrideButton && (
                                <Grid item xs={12}>
                                    <Button
                                        id="overrideButton"
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleOverrideClick}
                                    >
                                        Override
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Please provide the reason"
                                    name="reason"
                                    value={formik.values.reason}
                                    onChange={formik.handleChange}
                                    multiline
                                    rows={4}
                                    error={formik.touched.reason && Boolean(formik.errors.reason)}
                                    helperText={formik.touched.reason && formik.errors.reason}
                                    sx={{ width: "99%" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    id="submitButton"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={submitButtonDisabled || inProgress}
                                >
                                    {inProgress ? "Submitted..." : "Submit"}
                                </Button>
                                {deprioritizationHistory !== null && deprioritizationHistory.length > 0 && (
                                    <DeprioritizationHistory
                                        history={deprioritizationHistory.sort((a, b) => new Date(b.ingestionTime) - new Date(a.ingestionTime))}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        open={open || isFailed}
                        autoHideDuration={9000}
                        onClose={handleClose}
                        message={isFailed ? "Recording the response failed. Retry the operation, and if it still fails, contact aceengagedev@microsoft.com." : "Your response has been successfully recorded. Please allow a few seconds to refresh the view."}
                    />
                </>
            )}
        </>
    ) : (
        <Grid container rowSpacing={3} justifyContent="center">
            <Grid item xs={10.5}>
                <Alert severity="warning" variant="standard">
                    <AlertTitle>Insufficient Permission</AlertTitle>
                    <Typography gutterBottom variant="subtitle2" component="div">
                        You do not have sufficient permission to perform this operation. If you think this is invalid, please contact ACE Engage Dev Team at{" "}
                        <a href="mailto:aceengagedev@microsoft.com" rel="noreferrer">
                            aceengagedev@microsoft.com
                        </a>
                    </Typography>
                </Alert>
            </Grid>
        </Grid>
    );
};

export default Deprioritization;
