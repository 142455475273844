import { useState } from 'react';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import "../NextSteps.css";

const SuccessConfirmation = () => {

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className='escalate'>
            <div className='NJfJb'>
              <div className='duf3cw-ctrl'>
              <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="success">
                        <AlertTitle>Request Recieved</AlertTitle>
                        <Typography gutterBottom variant="subtitle2" component="div" className="ACE_Escalate_Label">
                            <p>We have received your escalation request and it is in progress. An email with a tracking number for the escalation will be sent to you shortly.</p>
                            <p>You may close this window. No further action is required from you at this time.</p>
                        </Typography>
                    </Alert>
                </Stack>
                <Stack direction="row" spacing={2} style={{justifyContent:"center", marginTop:"1.5rem"}}>
                  <Button onClick={handleClose}>I understand & Exit</Button>
                </Stack>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default SuccessConfirmation;