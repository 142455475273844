import { Paper } from "@mui/material";
import "./containers.css";

const TheFooter = () => {

  return (
    <Paper className="footer-container">
      <div></div>
      <div>© 2024 - Azure CXP ACE</div>
      <a href="https://privacy.microsoft.com/en-US/data-privacy-notice" target="_blank" rel="noreferrer" className="data-privacy">Data Privacy</a>
    </Paper>
  );
};

export default TheFooter;