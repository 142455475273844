import { Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { ACEEngageContext } from "../../../contexts/ACEEngageContext";
import moment from "moment";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const SRDetails = ({data}) => {

    const {setContext} = useContext(ACEEngageContext);

    useEffect(() => {
        setContext((prevContext) => ({
            ...prevContext,
            CaseNumber: data.caseNumber,
            CaseTitle: data.title,
            SubscriptionId: data.subscriptionId,
            CaseSeverity: data.severity === 4 ? "C" : data.severity === 3 ? "B" : data.severity === 2 ? "A" : "1",
            CaseCreatedOn: data.caseCreatedOn,
            CaseOwner: data.caseOwner,
            CaseState: data.state,
            SupportQueue: data.queue,
            PlanningCategory: data.productPlanningCategory,
            ProductName: data.productJson?.productName,
            SupportTopic: data.productJson?.supportTopic,
            SupportSubtopic: data.productJson?.supportSubTopic,
            ACEOwnerContacted: false,
            RequiresFurtherEscalation: false,
            HasActiveLeadsOnCallIcM: true
          }));
    }, [data]);

  return (
    <div className="bh13Qc nuboAb aneZWd">
        <div className="cv2VAd innercard" style={{padding:"16px"}}>
            <Grid container rowSpacing={2} spacing={0} alignItems="center" direction="row">
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Case Number:
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        <a href={`https://azuresupportcenter.msftcloudes.com/solutionexplorer?srId=${data.caseNumber}`} target="_blank" rel="noreferrer" className="ACE_Escalate_Link">{data.caseNumber}</a>
                    </Typography> 
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Severity:
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        Sev {data.severity === 4 ? "C" : (data.severity === 3 ? "B" : (data.severity === 2 ? "A" : "1"))}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Created:
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {moment(data.caseCreatedOn).format("Do MMM, hh:mm A")} (local)
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Case Owner:
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    {data.caseOwner ? 
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Avatar sx={{ width: 27, height: 27 }} alt={data.caseOwner} src={`https://microsoft.sharepoint.com/teams/azure_ace_program/_layouts/15/UserPhoto.aspx?Size=L&AccountName=${data.caseOwner}`} />
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                <a href={`https://teams.microsoft.com/l/chat/0/0?users=${data.caseOwner}`} target="_blank" rel="noreferrer" className='ACE_Escalate_Link'>
                                    {data.caseOwner.replace("@microsoft.com","").toUpperCase()}
                                </a>
                            </Typography> 
                       </Stack>
                       :
                        <Typography gutterBottom variant="subtitle2" component="span" style={{color:"red"}}>
                            [Pending Assignment]
                        </Typography>
                    }
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        State:
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {data.state}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Status:
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {data.status}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Support Queue:
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {data.queue}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Category:
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {data.productPlanningCategory}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        SAP:
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {data.productJson?.sapPath}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Title:
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {data.title}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    </div>  
  );
}

export default SRDetails;