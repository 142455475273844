import React from 'react';
const legendStyle = {
  position: 'absolute',
  top: '60px',
  right: '10px',
  padding: '8px',
  fontSize: '12px',
  fontWeight: 'Normal',
  color: '#333',
  textAlign: 'right',
};

const TimezoneLegend = () => {
  return (
    <div style={legendStyle}>
      All displayed times are in the local time zone.
    </div>
  );
};

export default TimezoneLegend;
