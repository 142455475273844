import { Alert, AlertTitle, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import "./Message.css";

const CaseClosed = () => {
return(
        <Grid item xs={11} className="message">
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error" variant="outlined">
                    <AlertTitle>Case Closed</AlertTitle>
                    <ul className="recommendations">
                        <li>
                            <Typography gutterBottom variant="body1" component="span">
                                Only <strong>Open</strong> cases can be escalated to Azure ACE
                            </Typography>
                        </li>
                    </ul>
                </Alert>
            </Stack>
        </Grid>
    );
};

export default CaseClosed;