import { Grid, Typography } from "@mui/material";

const Invalid = ({ error }) => {
    return (
        <Grid container rowSpacing={1} textAlign={"center"}>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h6" component="div">
                    {error}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography gutterBottom variant="body1" component="div">
                    Error is permanent, retrying will not help
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography gutterBottom variant="subtitle2" component="div">
                    If you think this is invalid, please contact ACE Engage Dev Team at {" "}
                    <a href="mailto:aceengagedev@microsoft.com" rel="noreferrer">aceengagedev@microsoft.com</a>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Invalid;