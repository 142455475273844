import { useLocation } from "react-router-dom";
import { Suspense, useContext, useEffect, useState } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../appInsights";
import { ACEEngageContext } from "../contexts/ACEEngageContext";
import Loading from "../components/utilities/Loading";
import WelcomeScreen from "../components/messages/welcome/WelcomeScreen";
import HomeContainer from "../components/home/container/HomeContainer";
import Deprioritization from "../components/home/deprioritization/Deprioritization";
import Invalid from "../components/messages/error/Invalid";

function findCaseInsensitiveParam(params, paramName) {
  for (const [key, value] of params.entries()) {
    if (key.toLowerCase() === paramName.toLowerCase()) {
      return value;
    }
  }
  return null;
}

const Home = () => {
  const [request, setRequest] = useState('');

  const { context, setContext } = useContext(ACEEngageContext);
  const { CaseNumber } = context;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sr = findCaseInsensitiveParam(params, 'sr');
  const incidentId = findCaseInsensitiveParam(params, 'icm');

  useEffect(() => {
    if (sr) {
      appInsights.trackTrace({
        message: `ASC Redirection: User redirected from ASC with Support Request Number ${sr}`,
        severityLevel: SeverityLevel.Information
      });
      document.getElementById("txt_SR_search_term").value = sr;
      setContext((prevContext) => ({
        ...prevContext,
        CaseNumber: sr,
      }));
    }
    else if (incidentId) {
      appInsights.trackTrace({
        message: `ACE OnCall De-prioritization`,
        severityLevel: SeverityLevel.Information
      });
      document.getElementById("txt_ICM_search_term").value = incidentId;
      setContext((prevContext) => ({
        ...prevContext,
        CaseNumber: incidentId,
      }));
      setRequest("icm");
    }
  }, [location]);

  if (request === "icm") {
    return (
      <Suspense fallback={<Loading />}>
        <Deprioritization key={CaseNumber} incidentId={CaseNumber} />
      </Suspense>
    );
  }

  if (CaseNumber) {
    const regexResult = CaseNumber.match(/(?<=^|\D)(2[2-5][0-1][0-9][0-3][0-9]\d{10})|(1\d{14})(?=$|\D)/gi);

    if (regexResult === null) {
      appInsights.trackTrace({
        message: `Invalid SR: The provided SR "${CaseNumber}" is invalid`,
        severityLevel: SeverityLevel.Warning
      });
      return <Invalid error={"Invalid Support Request Number"} />
    } else {
      return (
        <Suspense fallback={<Loading />}>
          <HomeContainer SupportTicketId={regexResult} />
        </Suspense>
      );
    }
  } else {
    return <WelcomeScreen />;
  }
};

export default Home;