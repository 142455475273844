import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Alert, AlertTitle, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { appInsights } from "../../../../appInsights";
import "./CustomerMessage.css";

const FailedToFetchCustomer = ({subscriptionId}) => {
    appInsights.trackTrace({ message: 'Error: An error occured while fetching the customer details for the subscription id ' + subscriptionId, severityLevel: SeverityLevel.Error });
return(
    <Grid item xs={12} className="message">
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">
                <AlertTitle>An error occured</AlertTitle>
                    The customer details cannot be retrieved, which may be due to any of the following reasons:
                    <ul className="recommendations">
                        <li>The support ticket is not associated with the Azure Product.</li>
                        <li>The support ticket does not contain subscription details.</li>
                        <li>An error occured while fetching the customer details.</li>
                    </ul>
                    <Typography variant="body2" gutterBottom>
                              If you believe it is due to an error, please send an email to {" "}
                                    <a href={`mailto:aceengagedev@microsoft.com?subject=[ACE Engage] - Unable to fetch customer details`} target='_blank' rel="noreferrer" className="ACE_Escalate_Link">
                                      ACE Engage Dev
                                    </a> team with the details.
                    </Typography>
            </Alert>
        </Stack>
    </Grid>
    );
};

export default FailedToFetchCustomer;