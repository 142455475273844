import { selector, selectorFamily } from "recoil";
import profileService from "../services/profileService";

const userQuery = selector({
  key: "userQuery",
  get: async () => {
    const result = await profileService.getUserInformation();
    return result;
  },
});

const groupsQuery = selector({
  key: "groupsQuery",
  get: async () => {
    const result = await profileService.getMemberGroups();
    return result.value;
  },
});

const photoQuery = selector({
  key: "photoQuery",
  get: async () => {
    const result = await profileService.getProfilePhoto();
    return result;
  },
});

const userHierarchyQuery = selectorFamily({
  key: "userHierarchyQuery",
  get: (userEmail) => async () => {
    if (!userEmail || typeof userEmail !== "string") {
      return null;
    }

    try {
      const result = await profileService.getUserHierarchyAsync(userEmail);
      return result;
    } catch (error) {
      console.error("Error in userHierarchyQuery:", error);
      return null;
    }
  },
});

export { groupsQuery, photoQuery, userQuery, userHierarchyQuery };