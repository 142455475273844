import {useContext, useState} from "react";
import { ACEEngageContext } from "../../../../contexts/ACEEngageContext";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from "@mui/material/Typography";

const ACEOwnerContacted = () => {

    const {context, setContext} = useContext(ACEEngageContext);
    const [selection, setSelection] = useState(context.ACEOwnerContacted ? "YES" : "NO");

    const handleACEOwnerContactedChange = (event) => {
        setContext((prevContext) => ({
            ...prevContext,
            ACEOwnerContacted: event.target.value === "YES" ? true : false,
          }));          
        setSelection(event.target.value);
    }

return(
        <>
            <Typography variant="body1" component="div" gutterBottom style={{display:"flex", alignItems:"center"}}>
                <span style={{marginRight:"2rem"}}>Did you reach out to the engaged ACE?</span>
                    <RadioGroup row name="ace-owner-contacted-group" value={selection} 
                        onChange={handleACEOwnerContactedChange} >
                        <FormControlLabel value="YES" control={<Radio />} label="YES" />
                        <FormControlLabel value="NO" control={<Radio />} label="NO" />
                    </RadioGroup>
            </Typography>
            {
                !context.ACEOwnerContacted &&
                <Typography variant="body2" gutterBottom style={{color:"darkred"}}>
                    <strong>Required Action</strong>: For additional assistance, please contact the ACE who is already engaged.
                </Typography>
            }
        </>
    );
}

export default ACEOwnerContacted;