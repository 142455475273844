import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import TheHeader from "./TheHeader";
import { Paper } from "@mui/material";
import TheFooter from "./TheFooter";
import "./containers.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const TheLayout = () => {

  return (
    <>
        <Paper style={{ height: "100vh" }}>
          <Box sx={{ display: "flex" }}>
            <TheHeader />
            <Box
              component="main"
              sx={{ flexGrow: 1 }}
              pl={3}
              pt={2}
              pb={0}
              pr={0}
            >
              <DrawerHeader />
              <Box component="div" className="outlet">
                <Outlet />
              </Box>
            </Box>
            <TheFooter />
          </Box>
        </Paper>
    </>
  );
};

export default TheLayout;