import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {useEffect, useContext} from "react";
import { useCustomerBySubscriptionId } from "../../../hooks/customer";
import { ACEEngageContext } from "../../../contexts/ACEEngageContext";
import DisplayCustomerACE from "../vteam_contacts/DisplayCustomerACE";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EmailIcon from '@mui/icons-material/Email';
import FailedToFetchCustomer from "./message/FailedToFetchCustomer";
import ACEManagedCustomer from "./message/ACEManagedCustomer";
import NonOnboardedSubscription from "./message/NonOnboardedSubscription";
import NonACEManagedCustomer from "./message/NonACEManagedCustomer";

const CustomerDetails = () => {

    const {context, setContext} = useContext(ACEEngageContext);
    const customer = useCustomerBySubscriptionId?.(context.SubscriptionId);

    const { customerName, friendlyName, tpid, isS500, isSubInACEScope, isCritical, acedl, acePrimaryAlias, aceM1Alias } = customer || {};

    useEffect(() => {
        if(customer)
        {
            setContext((prevContext) => ({
                ...prevContext,
                CustomerName: customerName,
                FriendlyCustomerName: friendlyName,
                TPID: tpid,
                IsACES500Customer: isS500,
                IsSubInACEScope: isSubInACEScope,
                IsCriticalSubscription: isCritical,
                ACEDL: acedl || null,
                PrimaryACE: acePrimaryAlias || null,
                ACEM1: aceM1Alias || null,
                CustomerPlaybook: customer.customerKnowMe || null,
                IsVTeamMemberAvailable: customer.availableACEs?.length > 0 || false,
                CountOfAvailableVTeamMembers: customer.availableACEs?.length || 0,
              }));
        }
    }, [customer, customerName, friendlyName, tpid, isS500, isSubInACEScope, isCritical, acedl]);

    if(!customer)
    {    
        return <FailedToFetchCustomer subscriptionId={context.SubscriptionId} />;
    }

  return (
            <Grid container rowSpacing={2} spacing={0} alignItems="center" direction="row">
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Customer:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        <a href={`https://cxp.azure.com/cxobserve/customers/ch:customer::tpid:${customer.tpid}/summary`} rel="noreferrer" target="_blank" className="ACE_Escalate_Link">{customer.customerName}</a>
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        TPID:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        <a href={`https://cxp.azure.com/cxobserve/customers/ch:customer::tpid:${customer.tpid}/summary`} rel="noreferrer" target="_blank" className="ACE_Escalate_Link">{customer.tpid}</a>
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Is ACE S500?
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {customer.isS500 ? "YES" : "NO"}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        SubscriptionId:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        <a href={`https://cxp.azure.com/cxobserve/subscriptions/ch:subscription::guid:${customer.subscriptionId}/summary`} rel="noreferrer" target="_blank" className="ACE_Escalate_Link">{customer.subscriptionId}</a>
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                        Subscription:
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography gutterBottom variant="subtitle2" component="span">
                        {customer.subscriptionName}
                    </Typography>
                </Grid>
                { 
                    customer.isS500 &&
                    <>
                        <Grid item xs={1}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                Sub Coverage:
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography gutterBottom variant="subtitle2" component="span">
                                <Tooltip title={customer.isSubInACEScope ? "The subscription is onboarded to Azure ACE Program" : "The subscription is not onboarded to Azure ACE Program"}>
                                    <IconButton>
                                        {customer.isSubInACEScope ? (
                                            <CheckCircleIcon style={{color:"darkgreen"}}/>
                                        ) : (
                                            <CancelIcon style={{color:"darkred"}}/>
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                            Sub Category:
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography gutterBottom variant="subtitle2" component="span">
                                {
                                   customer.isCritical ?
                                   <Tooltip title="The subscription is marked as Critical">
                                       <IconButton>
                                           <GppMaybeIcon style={{color:"darkred"}}/>
                                       </IconButton>
                                   </Tooltip> :
                                   <Tooltip title="The subscription is marked as Non-Critical">
                                       <IconButton>
                                           <VerifiedUserIcon style={{color:"darkgreen"}}/>
                                       </IconButton>
                                   </Tooltip>                                    
                                }
                            </Typography>
                        </Grid>
                        {
                            customer.acedl &&
                            <>
                                <Grid item xs={1}>
                                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                        ACE DL:
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0.5}>
                                        <EmailIcon style={{color:"royalblue"}} />
                                        <Typography gutterBottom variant="subtitle2" component="span">
                                            <a href={`mailto:${customer.acedl}?subject=Need ACE Assistance`} target='_blank' rel="noreferrer" className="customer_ace_link customer_ace_name">
                                                {customer.acedl.replace("@MICROSOFT.COM", "")}
                                            </a>
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </>
                        }
                        {
                            customer.customerKnowMe &&
                            <>
                                <Grid item xs={1}>
                                    <Typography gutterBottom variant="subtitle2" component="span" className="ACE_Escalate_Label">
                                        Playbook:
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography gutterBottom variant="subtitle2" component="span" className="NoAgent">
                                        <a href={customer.customerKnowMe} target="_blank" rel="noreferrer" className="ACE_Escalate_Link">Customer Playbook</a>
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            customer.availableACEs?.length > 0 &&
                            <>
                                <Grid item xs={2}>
                                    <Typography gutterBottom variant="subtitle2" className="ACE_Escalate_Label">
                                        Customer Aligned vTeam:
                                    </Typography>
                                </Grid>
                                {customer.availableACEs.map((ace) => (
                                    <Grid item xs={2} key={ace}>
                                        <DisplayCustomerACE
                                            TeamsUrl={`https://teams.microsoft.com/l/chat/0/0?users=${ace}@microsoft.com`}
                                            Name={ace}
                                            Photo={`https://microsoft.sharepoint.com/teams/azure_ace_program/_layouts/15/UserPhoto.aspx?Size=L&AccountName=${ace}@microsoft.com`}
                                        />
                                    </Grid>
                                ))}
                            </>
                        }
                    </>
                }
                { (customer.isS500 && customer.isSubInACEScope) && <ACEManagedCustomer customerName={customer.friendlyName} /> }
                { (customer.isS500 && !customer.isSubInACEScope) && <NonOnboardedSubscription /> }
                { !customer.isS500 && <NonACEManagedCustomer /> }
            </Grid>
  );
}

export default CustomerDetails;