import { createContext, useState } from "react";

export const ACEEngageContext = createContext();

const ACEEngageContextProvider = (props) => {
const [context, setContext] = useState({
    "UserName": "",
    "UserEmail": "",
    "UserJobTitle": "",
    "UserM1": "",
    "UserM2": "",
    "UserM3": "",
    "HasPortalAccess": true,
    "HasEscalationAccess": false,
    "CaseNumber":"",
    "CaseTitle": "",
    "SubscriptionId":"",
    "CaseSeverity":"",
    "CaseCreatedOn": "",
    "CaseOwner":"",
    "CaseState": "",
    "SupportQueue": "",
    "PlanningCategory": "",
    "ProductName":"",
    "SupportTopic":"",
    "SupportSubtopic":"",
    "CustomerName":"",
    "FriendlyCustomerName":"",
    "TPID": "",
    "IsACES500Customer":false,
    "IsSubInACEScope": false,
    "IsCriticalSubscription": false,
    "ACEDL": "",
    "PrimaryACE": "",
    "ACEM1": "",
    "CustomerPlaybook": "",
    "IsVTeamMemberAvailable": false,
    "CountOfAvailableVTeamMembers": 0,
    "HasActiveACEOnCallIcM":false,
    "HasActiveLeadsOnCallIcM":false,
    "HasActivePGIcM":false,
    "ACEOwnerContacted":false,
    "RequiresFurtherEscalation":false,
    "CustomerTemperature":"",
    "EscalationReason":"",
    "IssueDescription":"",
    "CustomerImpact":"",
    "BusinessImpact":"",
    "CustomerBridge":"",
    "InternalBridge":"",
    "CurrentStatus":"",
    "AdditionalRecipients":""
});

return(
        <ACEEngageContext.Provider value={{context, setContext}}>
            {props.children}
        </ACEEngageContext.Provider>
    );
}

export default ACEEngageContextProvider;