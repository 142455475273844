import { Grid } from "@mui/material";
import { ACEEngageContext } from "../../../contexts/ACEEngageContext";
import { useSupportTicketById } from "../../../hooks/supportTicket";
import { useCallback, useContext } from "react";
import HomeStepper from "../stepper/HomeStepper";
import SRDetails from "../sr_details/SRDetails";
import Error from "../../messages/error/Error";
import InsufficientPermission from "../../messages/insufficient_permission/InsufficientPermission";
import CaseClosed from "../sr_details/message/CaseClosed";
import "./HomeContainer.css";

const HomeContainer = ({SupportTicketId}) => {

    const { context } = useContext(ACEEngageContext);

    const useSupportTicketByIdMemo = useCallback(useSupportTicketById, []);
    const ticket = useSupportTicketByIdMemo?.(SupportTicketId);

return(
    <>
        { !context.HasEscalationAccess && <InsufficientPermission /> }
        {
            ticket ?
            <Grid container rowSpacing={0} spacing={0} justifyContent="center">
                <Grid item xs={11}>
                    <div className="bh13Qc nuboAb aneZWd">
                        <div className="cv2VAd innercard">
                            <Grid container rowSpacing={0} spacing={0}>
                                <Grid item xs={12}>
                                    <SRDetails data={ticket} />
                                </Grid>
                                { ticket.state !== "Open" && <CaseClosed /> }
                                <Grid item xs={12}>
                                    <HomeStepper data={ticket} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid> :
            <Error />
        }
    </>
    );
}

export default HomeContainer;