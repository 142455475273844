import { Avatar, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from "moment";
import { Divider } from '@mui/material';
import "../IncidentDetails.css";

const Incident = ({incident}) => {
   return(
       <>
        <Grid item xs={12} md={6} lg={2} className='incident_head'>
            <Grid container rowSpacing={1} direction="row" justifyContent="center" alignItems="center" textAlign={"center"}>
                <Grid item xs={12}>
                    <a href={`https://portal.microsofticm.com/imp/v3/incidents/details/${incident.incidentId}/home`} target="_blank" rel='noreferrer' className='ACE_Escalate_Link'>
                        {incident.incidentId}
                    </a>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom className={incident.status}>
                        {incident.status}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                        {`Severity ${incident.severity}`}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={10} alignSelf={"center"}>
            <Grid container rowSpacing={1} direction="row">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                        {incident.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div style={{borderBottom:"1px solid lightgray"}}></div>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Stack direction="row" spacing={1} alignItems="baseline">
                            <Typography variant="subtitle2" gutterBottom className='ACE_Escalate_Label'>
                                Owning Team Id:
                            </Typography>
                            <span className="spnIncidentValue">{incident.owningTeamName}</span>
                        </Stack>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle2" gutterBottom className='ACE_Escalate_Label'>
                                Owner:
                            </Typography>
                            <Stack direction="row" spacing={1} alignItems="self-start">
                                {incident.owningContactAlias ? 
                                    <>
                                        <Avatar sx={{ width: 27, height: 27 }} alt={incident.owningContactAlias} src={`https://microsoft.sharepoint.com/teams/azure_ace_program/_layouts/15/UserPhoto.aspx?Size=L&AccountName=${incident.owningContactAlias}@microsoft.com`} />
                                        <a href={`https://teams.microsoft.com/l/chat/0/0?users=${incident.owningContactAlias}@microsoft.com`} target="_blank" rel='noreferrer' className='ACE_Escalate_Link'>
                                            {incident.owningContactAlias}
                                        </a>
                                    </> :
                                    <Typography gutterBottom variant="subtitle2" component="span" className="NoAgent">
                                        [Not Assigned]
                                    </Typography>
                                }
                            </Stack>
                        </Stack>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Stack direction="row" spacing={1} alignItems="baseline">
                            <Typography variant="subtitle2" gutterBottom className='ACE_Escalate_Label'>
                                Created:
                            </Typography>
                            <span className="spnIncidentValue">{moment(incident.createDate).format("Do MMM, hh:mm A")}</span>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
       </>
   );
}

export default Incident;