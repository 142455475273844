import { Alert, AlertTitle, Grid } from "@mui/material";
import { Stack } from "@mui/system";

const NonOnboardedSubscription = () => {
return(
    <Grid item xs={12} className="message">
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="warning">
                <AlertTitle>Subscription not in Scope</AlertTitle>
                    The subscription is not onboarded to Azure ACE program; however, you can still proceed with the escalation
            </Alert>
        </Stack>
    </Grid>
    );
};

export default NonOnboardedSubscription;