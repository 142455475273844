import { useState, useEffect, Suspense, useContext } from 'react';
import { TabContext } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { appInsights } from '../../../appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import CustomerDetails from '../customer_details/CustomerDetails';
import IncidentDetails from '../incident_details/IncidentDetails';
import Loading from '../../utilities/Loading';
import NextSteps from '../next_steps/NextSteps';
import FailedToFetchCustomer from '../customer_details/message/FailedToFetchCustomer';
import { ACEEngageContext } from '../../../contexts/ACEEngageContext';
import "./HomeStepper.css";

const HomeStepper = ({data}) => {

    const {context} = useContext(ACEEngageContext);
    const [isEligibleForEscalation, setIsEligibleForEscalation] = useState(false);
    const [value, setValue] = useState('CustomerDetails');

    const handleChange = (event, newValue) => {
        if(newValue === 'CustomerDetails')
        {
            appInsights.trackTrace({ message: 'Stage Change (Tab): The user has switched to Customer Details stage', severityLevel: SeverityLevel.Verbose });
        }
        else if(newValue === 'EngineeringEngagement')
        {
            appInsights.trackTrace({ message: 'Stage Change (Tab): The user has switched to Engineering Engagement stage', severityLevel: SeverityLevel.Verbose });
        }
        else if(newValue === 'Escalation')
        {
            appInsights.trackTrace({ message: 'Stage Change (Tab): The user has switched to Escalation stage', severityLevel: SeverityLevel.Verbose });
        }
      setValue(newValue);
    };

    const handleGoToEngineeringEngagement = () => {
        appInsights.trackTrace({ message: 'Stage Change (Button): The user has switched to Engineering Engagement stage', severityLevel: SeverityLevel.Verbose });
        setValue("EngineeringEngagement");
    }

    const handleGoToEscalation = () => {
        appInsights.trackTrace({ message: 'Stage Change (Button): The user has switched to Escalation stage', severityLevel: SeverityLevel.Verbose });
        setValue("Escalation");
    }

    useEffect(() => {
        setValue('CustomerDetails');
    }, [data]);

    //This ensures that useEffect will only execute when the context object reference changes, not when its properties change.
    useEffect(() => {
        const IsEligibleForEscalation =
            context.CaseState === 'Open' &&
            context.IsACES500Customer &&
            ((!context.HasActiveLeadsOnCallIcM && !context.HasActiveACEOnCallIcM) ||
            (context.HasActiveACEOnCallIcM && context.ACEOwnerContacted && context.RequiresFurtherEscalation));

            setIsEligibleForEscalation(IsEligibleForEscalation);
      }, [context]);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab icon={<InfoIcon />} iconPosition="start" value="CustomerDetails" label="Customer Details" />
                    <Tab icon={<NavigateNextIcon />} iconPosition="start" value="EngineeringEngagement" label="Engineering Engagement" disabled={!data.subscriptionId} />
                    <Tab icon={<NavigateNextIcon />} iconPosition="start" value="Escalation" label="Escalation" disabled={!isEligibleForEscalation} />
                </TabList>
            </Box>
            <TabPanel value="CustomerDetails">
                <Suspense fallback={<Loading />}>
                    <Grid container rowSpacing={4} direction="row" justifyContent="center" alignItems="center">
                        {
                            data.subscriptionId ?
                                <>
                                    <Grid item xs={12}>
                                        <CustomerDetails />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button color='primary' variant="outlined" onClick={handleGoToEngineeringEngagement} endIcon={<NavigateNextIcon />}>NEXT STEP</Button>
                                    </Grid>
                                </> :
                                <FailedToFetchCustomer />
                        }
                    </Grid>
                </Suspense>
            </TabPanel>
            <TabPanel value="EngineeringEngagement">
                <Suspense fallback={<Loading />}>
                    <Grid container rowSpacing={4} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <IncidentDetails />
                        </Grid>
                        {
                            isEligibleForEscalation &&
                            <Grid item xs={2}>
                                <Button color='primary' variant="outlined" onClick={handleGoToEscalation} endIcon={<NavigateNextIcon />}>NEXT STEP</Button>
                            </Grid>
                        }
                    </Grid>
                </Suspense>
            </TabPanel>
            <TabPanel value="Escalation">
                <Suspense fallback={<Loading />}>
                    <NextSteps />
                </Suspense>
            </TabPanel>
        </TabContext>
    </Box>
  );
}

export default HomeStepper;