import { Stack } from '@mui/system';
import Alert from "@mui/material/Alert/Alert";
import AlertTitle from "@mui/material/AlertTitle/AlertTitle";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid/Grid';
import moment from 'moment';
import "../IncidentDetails.css";

const LeadsAlreadyEngaged = ({aceIncidents}) => {
    
const _leadsOnCall = aceIncidents.filter((incident) => incident.owningTeamId === 58325);

if(_leadsOnCall.length <= 0) return null;

return(
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="warning" style={{minHeight:"16rem"}} variant="outlined">
                <AlertTitle style={{marginBottom:"1rem"}}>ACE Lead is currently engaged on this issue</AlertTitle>
                <Grid container justifyContent="flex-start">
                    <Grid item xs={4}>
                        <Typography gutterBottom variant="subtitle2" component="div" className="aae_c_lbl">
                            Reference Id
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div" className="aae_b_focusTextExtraSmall">
                            {_leadsOnCall[0].incidentId}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{borderLeft:"1px solid #e2dfdf"}}>
                        <Typography gutterBottom variant="subtitle2" component="div" className="aae_c_lbl">
                            Severity
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div" className="aae_b_focusTextExtraSmall">
                            {_leadsOnCall[0].severity}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{borderLeft:"1px solid #e2dfdf"}}>
                        <Typography gutterBottom variant="subtitle2" component="div" className="aae_c_lbl">
                            State
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div" className={`${_leadsOnCall[0].status} aae_b_focusTextExtraSmall`}>
                            {_leadsOnCall[0].status}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{marginTop:"1rem"}}>
                        <Typography gutterBottom variant="subtitle2" component="div" className="aae_c_lbl">
                            Owner
                        </Typography>
                        {_leadsOnCall[0].owningContactAlias ? 
                            <Typography gutterBottom variant="subtitle2" component="div" className="aae_b_focusTextExtraSmall">
                                <a href={`https://teams.microsoft.com/l/chat/0/0?users=${_leadsOnCall[0].owningContactAlias}@microsoft.com`} rel="noreferrer" target="_blank" className='ACE_Escalate_Link'>
                                    {_leadsOnCall[0].owningContactAlias}
                                </a>
                            </Typography>
                            :
                            <Typography gutterBottom variant="subtitle2" component="div" className="aae_b_focusTextExtraSmall" style={{color:"red"}}>
                                [Pending Assignment]
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={8} style={{borderLeft:"1px solid #e2dfdf", marginTop:"1rem",  marginBottom:".875rem"}}>
                        <Typography gutterBottom variant="subtitle2" component="div" className="aae_c_lbl">
                            Engagement Time
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" component="div" className="aae_b_focusTextExtraSmall">
                            {moment(_leadsOnCall[0].createDate).format("Do MMM, YYYY hh:mm A")} (local)
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="body2" component="div" style={{color:"darkred"}}>
                            <strong>Recommendation:</strong> Please collaborate with the assigned owner to monitor progress.
                        </Typography>
                    </Grid>
                </Grid>
            </Alert>
        </Stack>
    );
}

export default LeadsAlreadyEngaged;