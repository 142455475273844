import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../appInsights";
import { auth } from "../authentication/auth";
import configuration from "../configuration/index";
import moment from "moment";

const escalationService = {
  escalate: async (payload) => {
    appInsights.trackTrace({ message: "Initiating escalation...", severityLevel: SeverityLevel.Information });

    const logData = {
      escalatedOn : moment().utc().format("YYYY-MM-DD hh:mm:ss A"),
      caseNumber: payload.CaseNumber,
      customerTemperature: payload.CustomerTemperature,
      escalationReason: payload.EscalationReason
    };

    appInsights.trackTrace({ message: `Escalation metadata for support ticket ID ${payload.CaseNumber}:\n ${JSON.stringify(logData, null, 2)}`, severityLevel: SeverityLevel.Information });

    try {
      const token = await auth.acquireToken(configuration.aceAPIResource, {});
      const response = await fetch(
        `${configuration.aceAPI}/api/aceengage/escalate`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        appInsights.trackTrace({ message: `Escalation completed successfully for support ticket ID ${payload.CaseNumber}`, severityLevel: SeverityLevel.Information });
        return result;
      } else {
        appInsights.trackTrace({ message: `Escalation failed for support ticket ID ${payload.CaseNumber}. Status: ${response.status}`, severityLevel: SeverityLevel.Error });
        console.error(`Escalation failed. Status: ${response.status}`);
        return null;
      }
    } catch (error) {
      appInsights.trackTrace({ message: `Error during escalation for support ticket ID ${payload.CaseNumber}. Error: ${error}`, severityLevel: SeverityLevel.Error });
      console.error("Error during escalation:", error);
      return null;
    }
  },
};

export default escalationService;