import {  selectorFamily } from "recoil";
import customerService from "../services/customerService";

  const customerBySubscriptionIdQuery = selectorFamily({
    key: "customerBySubscriptionIdQuery",
    get: (subscriptionId) => async () => {
      if (!subscriptionId || typeof subscriptionId !== "string") {
        return null;
      }
  
      try {
        const result = await customerService.getCustomerBySubscriptionIdAsync(subscriptionId);
        return result;
      } catch (error) {
        console.error("Error in customerBySubscriptionIdQuery:", error);
        return null;
      }
    },
  });

  const availableVTeamMembersQuery = selectorFamily({
    key: "availableVTeamMembersQuery",
    get: (customerName) => async () => {
      if (!customerName) {
        return null;
      }
  
      try {
        const result = await customerService.getAvailableVTeamMembersAsync(customerName);
        return result;
      } catch (error) {
        console.error("Error in availableVTeamMembersQuery:", error);
        return null;
      }
    },
  });

  export { customerBySubscriptionIdQuery, availableVTeamMembersQuery };